/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/

  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }
  
  body,
  html {
    height: 100%;
    font-family: "VisbyCF-Heavy, sans-serif";
  }
  
  /*---------------------------------------------*/
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0px;
  }
  
  p {
    font-family: "VisbyCF-Heavy";
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
  }
  
  ul,
  li {
    margin: 0px;
    list-style-type: none;
  }
  
  /*---------------------------------------------*/
  input {
    outline: none;
    border: none;
  }
  
  textarea {
    outline: none;
    border: none;
  }
  
  textarea:focus,
  input:focus {
    border-color: transparent !important;
  }
  
  input:focus::-webkit-input-placeholder {
    color: transparent;
  }
  input:focus:-moz-placeholder {
    color: transparent;
  }
  input:focus::-moz-placeholder {
    color: transparent;
  }
  input:focus:-ms-input-placeholder {
    color: transparent;
  }
  
  textarea:focus::-webkit-input-placeholder {
    color: transparent;
  }
  textarea:focus:-moz-placeholder {
    color: transparent;
  }
  textarea:focus::-moz-placeholder {
    color: transparent;
  }
  textarea:focus:-ms-input-placeholder {
    color: transparent;
  }
  
  input::-webkit-input-placeholder {
    color: #adadad;
  }
  input:-moz-placeholder {
    color: #adadad;
  }
  input::-moz-placeholder {
    color: #adadad;
  }
  input:-ms-input-placeholder {
    color: #adadad;
  }
  
  textarea::-webkit-input-placeholder {
    color: #adadad;
  }
  textarea:-moz-placeholder {
    color: #adadad;
  }
  textarea::-moz-placeholder {
    color: #adadad;
  }
  textarea:-ms-input-placeholder {
    color: #adadad;
  }
  
  /*---------------------------------------------*/
  button {
    outline: none !important;
    border: none;
    background: transparent;
  }
  
  button:hover {
    cursor: pointer;
  }
  
  iframe {
    border: none !important;
  }
  
  /*//////////////////////////////////////////////////////////////////
      [ Utility ]*/
  .txt1 {
    font-family: "VisbyCF-Heavy";
    font-size: 15px;
    color: #999999;
    line-height: 1.5;
  }
  
  .txt2 {
    font-family: "VisbyCF-Heavy";
    font-size: 15px;
    color: #57b846;
    line-height: 1.5;
  }
  
  /*//////////////////////////////////////////////////////////////////
      [ login ]*/
  /* .samplebg{
        background-color: #4e73df;
      } */
  .limiter {
    width: 100%;
    margin: 0 auto;
  }

  .container-login100 {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding-left: 16vw;
  }
  .waveBg{
    background:url(../images/wave.svg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
  }
  .wave {
    position: fixed;
    width: 111%;
    height: 923px;
    left: 191.73px;
    top: -197.5px;
    /* background: #362A41; */
    mix-blend-mode: multiply;
    /* box-shadow: inset 11px -10px 136px #000000; */
  }
  
  /* .wrap-login100 {
    width: 390px;
    left: 137px;
    top: 100px;
    position: absolute;
  } */
  
  .logo {
    max-width: 100%;
    height: auto;
  }
  
  /*------------------------------------------------------------------
      [ Form ]*/
  
  .login100-form {
    width: 100%;
  }
  .font-face-title {
    font-family: "VisbyCF-Heavy";
  }
  .login100-form-title {  
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 46px;
  
    color: #4f4953;
    margin: 25px 0px;
  }
  
  .login100-form-avatar {
    
  }
  
  .login100-form-avatar img {
    
  }
  
  /*------------------------------------------------------------------
      [ Input ]*/
  
  .wrap-input100 {
    width: 100%;
    position: relative;
    border: 1px solid #b9b7ba;
    border-radius: 10px;
    margin-bottom:20px;
    height:55px;
  }
  
  .font-face-gm {
    font-family: "VisbyCF-Heavy";
    font-size: 16px;
  }
  .textreg, .textpass {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #4f4953;
    margin-bottom: 10px;
    text-align: left;
  }
  .input100 {
    font-family: "VisbyCF-Heavy";
    font-size: 17px;
    color: #555555;
    line-height: 1.2;
  
    display: block;
    width: 376px;
    height: 69px;
    background: transparent;
    padding: 0 5px;
    border-radius: 10px;
  }
  .input101 {
    font-family: "VisbyCF-Heavy";
    font-size: 17px;
    color: #555555;
    line-height: 1.2;
    display: block;
    width: 376px;
    height: 49px;
    background: transparent;
    padding: 0 5px;
    border-radius: 5px;
  }
  .input102 {
    font-family: "VisbyCF-Heavy";
    font-size: 17px;
    color: #555555;
    line-height: 1.2;
    display: block;
    width: 150px;
    height: 39px;
    background: transparent;
    padding: 0 5px;
    border-radius: 5px;
  }
  .verify{
    display:flex;
    gap:30px;
    margin:30px 0 20px 20px;
    /* width:800px; */
  }
  .remember{
    display:flex;
    gap:20px;
    
    margin-top: -50px;
  }
  .remember p{
    color:blue;
    margin-top: 20px;
  }
  .valid{
    color:#57b846;
  }
  .invalid{
    color:red;
  }
  #message{
    border:2px solid #362A41;
    padding:10px;
    position:absolute;
    top:300px;
    left:820px;
    animation: fadeOutAnimation ease 6s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  #message1{
    border:2px solid #362A41;
    padding:10px;
    position:absolute;
    top:450px;
    left:750px;
    animation: fadeOutAnimation ease 6s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  #message2{
    border:2px solid #362A41;
    padding:10px;
    position:absolute;
    top:370px;
    left:790px;
    animation: fadeOutAnimation ease 6s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  @keyframes fadeOutAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

  .connect{
    color: #362A41;
    font-size: 30px;
    position:absolute;
    top:430px;
    left:790px;
  }
  .connect1{
    color: #362A41;
    font-size: 30px;
    position:absolute;
    top:610px;
    left:700px;
  }
  .connect2{
    color: #362A41;
    font-size: 30px;
    position:absolute;
    top:480px;
    left:740px;
  }
  .check{
    border:1 px solid green;
    background-color: green;
    width:20px;
    height:20px;
  }
  .uncheck{
    border:1px solid red;
    background-color: white;
    width:20px;
    height:20px;
  }
  .otp{
    margin:10px 0 0 0px;
    font-family: "VisbyCF-Heavy";
    font-size: 22px;
    color: #764F90;
    font-weight: 800;
    letter-spacing: 3px;
  }
  .verify-text{
    margin:20px 0 0 10px;
    font-family: "VisbyCF-Heavy";
    font-size: 16px;
    color: blue;
    font-weight: 800;
    letter-spacing: 1px;
  }
  .success{
    background-color: #b9b7ba;
    opacity:90%;
    height:100vh;
    width:100vw;
    position:fixed;
    top:0;
    
    
  }
  .content{
    width: 400px;
    height:150px;
    background: #fefefe;
    border-radius: 20px;
    padding:50px 50px;
    position:absolute;
    top:100px;
    left:350px;
  }
  .content2{
    width: 600px;
    height:200px;
    background: #fefefe;
    border-radius: 20px;
    padding:25px 50px;
    position:absolute;
    top:50px;
    right:200px;
    left:200px;
    z-index:1;
    font-size: 16px;
    font-weight: 600;
  }
  .content p{
    color:green;
    font-size: 20px;
  }
  .content button{
    font-size: 16px;
    font-weight: 600;
    margin:10px 0 0 100px;
  }
  .content2 p{
    font-size: 16px;
    font-weight: 600;
  }
  .modal-btn{
    padding:10px 20px;
    border:1px solid #362A41;
    background: #362A41;
    color:white;
    font-size: 16px;
    border-radius:10px;
  }
  .modal-btn2{
    padding:10px 20px;
    border:1px solid #362A41;
    background: white;
    color:#362A41;
    margin-left: 40px;
    font-size: 16px;
    border-radius:10px;
  }
  .modal-btn:hover{
    background: white;
    color:#362A41;
  }
  .modal-btn2:hover{
    background: #362A41;
    color:white;
  }
  .back{
    color:#362A41;
    font-size:40px;
    border:1px solid #555555;
    border-radius: 50%;
    width:60px;
    height:60px;
    font-weight:700;
    position:absolute;
    left:20px;
    top:20px;
  }
  .back i {
    position:relative;
    top:15px;
    left:10px;
  }
  /*---------------------------------------------*/
  .focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  
  .focus-input100::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  
    background: #4e73df;
  }
  
  .focus-input100::after {
    font-family: "VisbyCF-Heavy";
    font-size: 18px;
    color: #999999;
    line-height: 1.2;
  
    content: attr(data-placeholder);
    display: block;
    width: 100%;
    position: absolute;
    top: 15px;
    left: 0px;
    padding-left: 5px;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  .has-error {
    border-color: #ff1111;
    border-width: 2px;
  }
  
  .input100:focus + .focus-input100::after {
    top: -20px;
    font-size: 15px;
  }
  
  .input100:focus + .focus-input100::before {
    width: 100%;
  }
  
  .has-val.input100 + .focus-input100::after {
    top: -20px;
    font-size: 15px;
  }
  
  .has-val.input100 + .focus-input100::before {
    width: 100%;
  }
  
  /*------------------------------------------------------------------
      [ Button ]*/
  .container-login100-form-btn {
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .logo-in{
    position:absolute;
    right:30px;  
    width:1130px;
    /* margin: 70px 20px 0px 80px; */
  }
  .social-icons{
    display:flex;
    gap:20px;
    margin-left: 90px;
  }
  .login-form-btn {
    border:1px solid #714F90;
    background: #714F90;
    border-radius: 10px;
    font-family:"VisbyCF-Heavy";
    padding: 0 20px;
    height: 50px;
    width: 150px;
    color:#fff;
    font-weight:700;
    font-size:20px;
    margin-top: -20px;
  }
  .login-form-btn:hover{
    background: none;
    border:1px solid #714F90;
    color:#714F90;
  }
  .login100-form-btn {
    font-family:"VisbyCF-Heavy";
    font-size: 14px;
    /* color: #fff; */
    line-height: 1.2;
    text-transform: uppercase;
  
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 300px;
    height: 50px;
    background: #362a41;
    border-radius: 8px;
    color: #fff;
  
    box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
    -moz-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
    -webkit-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
    -o-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
    -ms-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  /* .login100-form-btn:hover {
    background-color: #fff;
    color:#362a41;
    border:1px solid #362a41;
    box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
    -moz-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
    -webkit-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
    -o-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
    -ms-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  } */
  .login101-form-btn {
    font-family:"VisbyCF-Heavy";
    font-size: 14px;
    line-height: 1.2;
    text-transform: uppercase;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 150px;
    height: 50px;
    background: #362a41;
    border-radius: 8px;
    color: #fff;
  
    box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
    -moz-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
    -webkit-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
    -o-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
    -ms-box-shadow: 0 10px 30px 0px rgba(64, 68, 67, 0.5);
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  .login101-form-btn:hover{
    background: none;
    border:1px solid #362A41;
    color:#362A41;
  }
  .error-message{
    font-size: 0.7rem;
    font-weight: 500;
    color: red;
    margin-top: 0.2rem;
  }
  /*------------------------------------------------------------------
      [ Alert validate ]*/
  
  .validate-input {
    position: relative;
  }
  .login-title{
    text-align: center;
    margin: 38px 0px 45px 0px;
    color:"#B9B7BA";
    font-size: 12px;
    font-weight: 700;
  }
  .login-title2{
    text-align: center;
    margin: 28px 0px 25px 0px;
    color:"#B9B7BA";
    font-size: 12px;
    font-weight: 700;
  }
  .hr{
    border:1px solid grey;
  }
  .forgot-password{
    text-align: right;
    margin: -38px 0px 25px 0px;
    color:"#B9B7BA";
    font-size: 12px;
    font-weight: 700;
  }
  .fa-google {
    background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }
  @media (min-width:850px) and (max-width: 920px){
    .wave{
     top: -227px;
    }
    .wrap-login100{
     left:40px;
   }
   }
   @media (min-width:720px) and (max-width: 849px){
     .wave{
      top: -340px;
      height:1200px;
     }
     .wrap-login100{
      left:40px;
    }
    .login100-form-title{
      font-size: 40px;
    }
    }
    @media (min-width:619px) and (max-width: 720px){
     .wave{
      top: -410px;
      height:1200px;
      left:100px;
     }
     .wrap-login100{
      left:40px;
    }
    .wrap-input100{
     width:290px;
    }
    .container-login100-form-btn{
      display:block;
    }
    }
    @media (min-width:540px) and (max-width: 619px){
     .wave{
      top: -425px;
      height:1200px;
      left:100px;
     }
     .wrap-input100{
       width:250px;
      }
     .wrap-login100{
      left:40px;
    }
    .container-login100-form-btn{
     display:block;
   }
   .container-login100-form-btn{
     width:300px;
   }
   .login100-form-title{
     font-size:40px;
   }
    }
    @media (min-width:420px) and (max-width: 540px){
     .wave{
      top: -445px;
      height:1200px;
      left:80px;
     }
     .wrap-login100{
      left:40px;
    }
    .wrap-input100{
     width:200px;
    }
    .login100-form-title{
      font-size:25px;
    }
    .container-login100-form-btn {
     width:200px;
    }
    }
    @media (min-width:320px) and (max-width: 420px){
     .wave{
      top: -475px;
      height:1200px;
      left:80px;
     }
     .wrap-login100{
      left:40px;
    }
    .wrap-input100{
     width:200px;
    }
    .login100-form-title{
      font-size:25px;
    }
    .container-login100-form-btn {
     width:200px;
    }
    }