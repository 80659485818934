
  .container {
    margin-top: 100px;
    padding: 32px;
  }
  
  .select-box {
    display: flex;
    width: 400px;
    flex-direction: column;
  }
  
  .select-box .options-container {
    background-color: rgb(54, 42, 65);
    color: #f5f6fa;
    max-height: 0;
    width: 100%;
    opacity: 0;
    transition: all 0.4s;
    border-radius: 8px;
    overflow: hidden;
  
    order: 1;
  }
  
  .selected {
    background-color: rgb(54, 42, 65);
    border-radius: 8px;
    margin-bottom: 8px;
    color: #f5f6fa;
    position: relative;
  
    order: 0;
  }
  
  .selected::after {
    content: "";
    /* background: url("img/arrow-down.svg"); */
    background-size: contain;
    background-repeat: no-repeat;
  
    position: absolute;
    height: 100%;
    width: 32px;
    right: 10px;
    top: 5px;
  
    transition: all 0.4s;
  }
  
  .select-box .options-container.active {
    max-height: 240px;
    opacity: 1;
    overflow-y: scroll;
  }
  
  .select-box .options-container.active + .selected::after {
    transform: rotateX(180deg);
    top: -6px;
  }
  
  .select-box .options-container::-webkit-scrollbar {
    width: 8px;
    background-color: rgb(54, 42, 65);
    border-radius: 0 8px 8px 0;
  }
  
  .select-box .options-container::-webkit-scrollbar-thumb {
    background-color: rgb(54, 42, 65);
    border-radius: 0 8px 8px 0;
  }
  
  .select-box .option,
  .selected {
    padding: 12px 24px;
    cursor: pointer;
  }
  
  .select-box .option:hover {
    background-color: rgb(54, 42, 65);
  }
  
  .select-box label {
    cursor: pointer;
  }
  
  .select-box .option .radio {
    display: none;
  }
 