.bold {
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 16px;
}

.btn-xl {
  width: 48%;
}

.border {
  border: none !important;
  box-shadow: none !important;
}

.header {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
}

.page-content {
  padding-top: 0 !important;
}

.font-size-16 {
  font-size: 16px;
}

.panel-footer {
  padding-bottom: 3% !important;
  position: relative;
  left: 300px;
  top: 0px;
  width:82%;
}

.block {
  display: inline-block;
}

.name {
  text-align: initial;
}

.padding-left {
  padding-left: 3px !important;
  padding-right: 0 !important;
}

.bottom {
  bottom: 20%;
}

  .time-size {
    /* font-size: 1rem; */
    top: 45px;
    position: absolute;
    left: 733px;
    background: white;
}

.white-box{
  background-color: black;
  height: 50vh;
  width: 20vw;
 margin-left: 550px;
}


#like-btn:hover {
  color: black;
}

/* search box css */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "VisbyCF-Heavy, Helvetica, sans-serif";
}

.topnav {
  position: relative;
  width: 912px;
  height: 80px;
  left: 300px;
  top: 50px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 4%);
  border-radius: 4px;
  padding : 10px 15px;
}
.searchbox {
  /* position: absolute; */
  /* background-color: black; */
  width: 450px;
  height: 45px;
  padding: 10px;
  /* left: 179px; */
  /* top: 5px; */
  /* align-self: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  /* background: rgba(54, 42, 65, 0.04); */
  background-color: whitesmoke;
  border-radius: 4px;
}
.textwhite-title {
  /* position: absolute; */
  width: 130px;
  /* height: 42px; */
  /* left: 15px; */
  /* top: 9px; */
  line-height: 19px;
  color: #4f4953;
  font-family: VisbyCFHeavy;
  font-weight: 700 !important;
}
/* .font-face-white-title {
  font-family: "VisbyCF-Heavy";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
} */
.rightnav{
  display: flex;
  align-items: center;
}


.css-b62m3t-container{
  width : 100%;
  background-color: whitesmoke!important;
}
.css-319lph-ValueContainer {
  background-color: whitesmoke !important;
  width: 100%;
}
.css-tlfecz-indicatorContainer {
  background-color: whitesmoke !important;
  border-radius: 2px;
  padding: 6px !important;
}
.css-1s2u09g-control {
  background-color: hsl(0, 0%, 100%) !important;
  border-color: hsl(0, 0%, 80%) !important;
  border-radius: 5px !important;
  border-style: none !important;
  border-width: 1px !important;
  /* background: black; */
}

.postcontainer {
  /* position: relative; */
  /* width: 650px; */
  width: 1020px;
  height: -3px;
  /* left: 287px; */
  /* top: 70px; */
  margin: 80px 0px 30px 300px;
  display: grid;
  grid-template-columns: 1fr .45fr;
}
.userposts {
  position: absolute;
  width: 912px;
  height: 370px;
  left: 318px;
  top: 230px;
  background: #ffffff;
  border-radius: 4px;
}
.btn:hover {
  color: white !important;
  text-decoration: none;
}


#wrapper #content-wrapper {
  /* background-color:#FCFBFF ; */
  /* width: 100% !important; */
  /* overflow-x: hidden !important; */
  /* height: 100vh !important; */
}
.panel {
  box-shadow: 0 1px 3px rgb(0 0 0 / 10%), 0 1px 2px rgb(0 0 0 / 18%) !important;
  /* background-color: white !important; */
}

/* input {
  display: block;
  outline: none;
  border: none !important;
  background-color: whitesmoke !important;
} */
/* .union {
  position: absolute;
  width: 13.5px;
  height: 13.5px;
  left: 1243px;
  top: 61px;

  background: rgba(79, 73, 83, 0.4);
} */
.user-pic {
  /* position: absolute; */
  /* width: 462px; */
  /* height: 30px; */
  /* left: 874px; */
  /* top: -1px; */
  /* background: rgba(54, 42, 65, 0.04); */
  border-radius: 4px;
  color: #4f4953;
  margin-left: 10px;
}
.active-user{
  font-family: VisbyCFHeavy;
  font-weight: 500;
}
.active-user1{
    font-family: VisbyCFThin;
    color: rgba(79, 73, 83, 0.5);
    font-size: small;
    /* position: relative; */
    /* top: -5px; */
}
.img-profile {
  /* position: absolute; */
  /* width: 352px; */
  /* height: 30px; */
  /* left: 419px;
  top: 2px; */
  z-index: 1;
  /* background: rgba(54, 42, 65, 0.04); */
  border-radius: 4px;
  color: #4f4953;
  height: 50px;
  width: 42px;
  padding-bottom: 11px;
  /* margin-bottom: 58px;*/
}
/* .input-post{
  border: 1px solid rgba(54, 42, 65, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
} */
.leftPostContainer{
  /* background-color: black; */
  display: flex;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 4%);
  border-radius: 4px;
  padding: 10px 20px;
  /* height: 110px; */
}
.imgContainer{
  margin-right: 10px;
}
.form-container-control{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.rightPostContainer{
  /* width:100%; */

}
/* 
.imageContainer{
  width: 100%;
  height: 100%;
} */
.form-control {
  /* display: block; */
  position: relative;
  width: 100%;
  height: 46px;
  padding: 6px 12px;
  font-size: 14px;
  /* line-height: 1.42857143; */
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc !important;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  outline: 0 !important;
  box-shadow: none !important;
}
.img-profile-post {
  /* position: absolute; */
  /* width: 352px; */
  /* height: 30px; */
  /* left: 20px;
  top: 25px; */
  z-index: 1;
  /* background: rgba(54, 42, 65, 0.04); */
  border-radius: 4px;
  color: #4f4953;
  height: 50px;
  width: 60px !important;
  /* padding-bottom: 11px; */
  /* margin-bottom: 58px;*/
}
/*.border-img{
  /* position: absolute;
    /* width: 352px; */
    /* height: 30px; */
    /* left: 20px;
    top: 25px;
    z-index: 1; */
    /* background: rgba(54, 42, 65, 0.04); */
    /* border-radius: 167px;
    border: 3px solid;
    color: #4f4953;
    height: 42px;
    width: 43px;
    padding-bottom: 11px; */
    /* margin-bottom: 58px;
} */
.btn-post {
  padding: 0 !important;
  /* top: -40px; */
  position: absolute;
  width: 100px;
  height: 33px;
  right: 2%;
  background: #4f4953;
  border-radius: 4px;
  color: white;
}
.eye-outline{
    /* top: -39px; */
    position: absolute;
    width: 46px;
    padding-top: 8px;
    padding-right: 16px;
    /* margin-top: 10px; */
    height: 33px;
    right: calc( 110px + 3.5% );
    /* background: #4f4953; */
    border-radius: 4px;
    color: black;
    /* border: 2px solid black; */
}
.down-outline{
  /* top: -38px; */
  position: absolute;
  width: 46px;
  /* padding-top: 8px; */
  padding-right: 18px;
  /* margin-top: 10px; */
  height: -2px;
  right : calc( 85px + 3% );
  /* background: #4f4953; */
  border-radius: 4px;
  color: black;
}
.smile-outline{
  top: -38px;
  position: absolute;
  width: 46px;
  padding-top: 8px;
  padding-right: 18px;
  /* margin-top: 10px; */
  height: -2px;
  left: 608px;
  /* background: #4f4953; */
  border-radius: 4px;
  color:#ccc;
}
.today{
  position: absolute;
  width: auto;
  height: 12px;
  left: 700px;
  /* top: 264px; */
  color:rgba(79, 73, 83, 0.31);
  font-size: 10px;
  line-height: 12px;
}
.img-responsive-post {
  position: relative;
  left: -8px;
  top: -411px;
  color: #4f4953;
  height: 41%;
  width: 9%;
}
.username-responsive {
  position: relative;
  left: -20px;
  top: -403px;
  color: #4f4953;
  height: 41%;
  width: 12%;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 108.5%;
  /* or 17px */
  color: #4f4953;
}

.dept-responsive {
  position: relative;
  left: -20px;
  top: -401px;
  color: #4f4953;
  height: 41%;
  width: 40%;
  font-style: normal;
  /* font-weight: bold; */
  font-size: 16px;
  line-height: 108.5%;
  color: #4f4953;
}
.user-details {
  padding-top: 2px;
  /* margin-top: 7px; */
  top: -177px;
  position: relative;
}
/* .user-wrap {
  margin-top: 28px;
  padding-top: 20px;
  padding-bottom: revert;
} */

/* .line-hr {
  position: absolute;
  width: 880px;
  height: 0px;
  left: 23px;
  top: 34px;
  border: 1px solid rgba(79, 73, 83, 0.1);
} */
.line-hr{
  
  width: 670px !important;
  left: 22px;
  position: relative;

}
.btn-lk-cmnt {
  position: absolute;
  width: 166px;
  height: 36px;
  left: 400px;
  background: #ffffff;
  /* border: 1px solid #efefef; */
  /* box-sizing: border-box; */
  /* box-shadow: 0px 24px 32px rgba(12, 22, 40, 0.07); */
  /* border-radius: 4px; */
}
/* .img-responsives{
  width: 30%;
  height: 20%;
} */

/* position: absolute;
width: 877px;
height: 0px;
left: 393px;
top: 334px;

border: 1px solid rgba(79, 73, 83, 0.1); */
/* 
margin-bottom: 32px;
margin-top: 0px; */


.card-header {
  padding: 0px !important;
  /* margin-bottom: 0; */
  margin-left: 17px !important;
  background-color: #ffffff !important;
  /* border-bottom: 1px solid #e3e6f0; */
  border-bottom: none !important;
}
.card-header {
  padding: 0px !important;
  margin-bottom: 0;
  /* background-color: #f8f9fc; */
  /* border-bottom: 1px solid #e3e6f0; */
  border-bottom: none !important;
}
hr {
  margin-bottom: 9px !important;
  margin-top: 0px !important;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #ffffff !important;
  border-top:none !important; ;
}
.cmnt-img-responsive{
    width: 50px;
    position: relative;
    left: 8px;
    z-index: 1;
    top: 53px;
    border-right: 2px solid whitesmoke;
    padding-right: 10px;
    margin-right: 10px;
}
.user-img-responsive{
  width: 50px;
  position: relative;
  left: 8px;
}
.input-comments {
  border-radius: 2% !important;
  position: absolute !important;
  left: 76px !important;
}

.add-border {
  border: 0.1px solid rgba(54, 42, 65, 0.2);
  padding: 5px !important;
}
.add-border1{
  margin-top:-10px;
}
.card-1{
  position: relative;
  margin-bottom: -6px;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 33%);
  left: 50px !important;
  width: 600px !important;

  
}
.card{
  /* position: relative; */
  margin-bottom: -6px;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 33%);
  left: 290px !important;
  /* width: 900px !important; */
  width: 82%;
 top:230px;
  
}
/* position: relative;
width: 801px;
height: 55px;
left: -31px;
top: -20px;
border: 1px solid rgba(54, 42, 65, 0.2);
box-sizing: border-box;
border-radius: 4px;
background-color: antiquewhite; */

.cmnt-input{
  width: 670px;
  height: 55px;
  border: 1px solid rgba(54, 42, 65, 0.2) !important;
  box-sizing: border-box;
  border-radius: 4px;
  top: 196px;
  position: absolute;
  padding-left: 63px;
  }
  
    .group-line-button {
      background: white;
      width: 128px;
      position: absolute;
      top: 97px;
      left: 360px;
      height: 51px;
      /* padding: 3px; */
      margin: 2px;
      border-radius: 4px;
      box-shadow: 0px 24px 32px rgb(12 22 40 / 7%);
  }
 
    .button-cmnt {
      width: 87px;
    position: relative;
    left: 580px;
    z-index: 1;
    top: -6px;
    background: #4f4953 !important;
    color: white !important;
  }


/* .post-list{
  position: absolute;
  width: 925px;
  height: 483px;
  left: 369px;
  top: 269px;

  background: #FFFFFF;
  border-radius: 4px;
} */

/* media screen display */
/*if lesser than 425px */
@media screen and (max-device-width: 425px) {
  .logo {
    /* width: 70%; */
    position: absolute;
    width: 81.19px;
    height: 44.68px;
    left: -3px;
    top: 5px;
  }
  .topnav {
    position: relative;
    width: 326px;
    height: 51px;
    left: 95px;
    top: 26px;
    background: #ffffff;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 4%);
    border-radius: 4px;
}
.textwhite-title {
 
  position: absolute;
  width: 68px;
  height: -14px;
  left: 48px;
  top: 4px;
  line-height: 18px;
  color: #4f4953;

}
.font-face-white-title {
  font-family: "VisbyCF-Heavy";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}
.img-profile {
  position: initial;
  /* width: 352px; */
  /* height: 30px; */
  left: 280px;
  top: 94px;
  /* background: rgba(54, 42, 65, 0.04); */
  border-radius: 4px;
  color: #4f4953;
  height: 50px;
  width: 42px;
  padding-bottom: 11px;
  /* margin-bottom: 58px; */
}
.searchbox {
  position: absolute;
  background-color: black;
  width: 140px;
  height: 36px;
  padding: 10px 15px !important;

  /* left: 103px;
  top: 4px; */

  /* background: rgba(54, 42, 65, 0.04); */
  border-radius: 4px;
}
.user-pic {
  /* position: absolute; */
    /* width: 0px; */
    height: 0px;
    left: 248px;
    font-size: 11px;
    top: 6px;
  /* background: rgba(54, 42, 65, 0.04); */
  border-radius: 4px;
  color: #4f4953;
  /* font-size: 20px; */
}
.active-user {
  font-family: VisbyCFHeavy;
  font-weight: 500;
  font-size: x-small;
}
.active-user1 {
  font-family: VisbyCFThin;
  color: rgba(79, 73, 83, 0.5);
  font-size: x-small;
  position: relative;
  top: -5px;
  font-weight: 600;
}
.sidebar {
  width: 14rem !important;
  background: #ffffff;
}

.sidebar-dark .nav-item .nav-link:active, .sidebar-dark .nav-item .nav-link:focus, .sidebar-dark .nav-item .nav-link:hover {
  color: whitesmoke !important;
  background-color: #4F4953 !important;
  border-radius: 4px;
  /* margin: 10px; */
  /* text-align: center; */
  /* margin-left: 5px; */
  /* margin-right: 29px; */
  text-align: center;
  padding: 10px 0rem;
  width: 4.5rem;
  margin: 6px;
}
.sidebar .nav-item .nav-link {
  text-align: center;
  padding: 10px 1rem;
  width: 6.5rem;
  margin: 2px;
}
 /* .nav-link :hover {
  text-align: center;
  padding: 10px 1rem;
  width: 6.5rem;
  margin: 6px;
  color: whitesmoke !important;
  background-color: #4F4953 !important;
  border-radius: 4px;
} */


.sidebar .nav-item {
  line-height: 7px;
  margin-left: -12px;
  position: relative;
  left: 0px;
  padding: 0px;
}
/* .postcontainer {
  position: absolute;
  width: 321px;
  height: -3px;
  left: 67px;
  top: 56px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 4%);
  border-radius: 4px;
  margin: 30px;
  padding: 20px;
  height: 110px;
} */
.postcontainer{
  display: grid;
  width: 100%;
  grid-template-columns: 1fr .45fr;
}
.leftPostContainer{
  width: 100%
}
.form-control {
  display: block;
  width: 260px;
  height: 46px;
  top: -10px;
  position: relative;
  left: 32px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc !important;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  outline: 0 !important;
  box-shadow: none !important;
}
.img-profile-post {
  /* position: absolute; */
  /* width: 352px; */
  /* height: 30px; */
  /* left: 5px;
  top: 14px; */
  z-index: 1;
  /* background: rgba(54, 42, 65, 0.04); */
  border-radius: 4px;
  color: #4f4953;
  height: 50px;
  width: 42px;
  padding-bottom: 11px;
  /* margin-bottom: 58px; */
}
.card {
  position: relative;
  margin-bottom: 24px;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 33%);
  left: 78px !important;
  width: 317px !important;
  top: 210px !important;
}
.btn-post {
  padding: 0 !important;
  top: -36px;
  position: absolute;
  width: 48px;
  height: 26px;
  left: 198px;
  background: #4f4953;
  border-radius: 4px;
  color: white;
}
.panel-footer {
  padding-bottom: 3% !important;
  position: relative;
  left: 34px;
  top: -9px;
}
.cmnt-input {
  width: 297px;
  height: 55px;
  border: 1px solid rgba(54, 42, 65, 0.2) !important;
  box-sizing: border-box;
  border-radius: 4px;
  top: 131px;
  position: absolute;
  left: 10px;
  padding-left: -46px;
}
.cmnt-img-responsive {
  width: 50px;
  position: relative;
  left: -5px;
  z-index: 1;
  top: 21px;
  border-right: 2px solid whitesmoke;
  padding-right: 10px;
  margin-right: 10px;
}
.button-cmnt {
  width: 87px;
  position: relative;
  left: 196px;
  z-index: 1;
  top: -16px;
  background: #4f4953 !important;
  color: white !important;
}
.line-hr {
  width: 300px;
  left: 9px;
  position: relative;
}
.time-size {
  /* font-size: 1rem; */
  top: 27px;
  /* position: absolute; */
  left: 215px;
  background: white;
  width: 86px !important;
  font-size: 8px;
  padding: 7px;
}
.p-2 {
  padding: 0px !important;
}
/* .comment-input{
  max-width: 680px !important;
} */
.user-img-responsive {
  width: 38px;
  position: relative;
  left: -12px;
}
.pt-2, .py-2 {
  padding-top: 0px !important;
}
.ml-3, .mx-3 {
  margin-left: 2px !important;
}
.mr-2{
  margin-right: 2px
}
.username{
  top: 7px;
  position: absolute;
  left: 44px;
  margin-right: 2px;
  font-size: smaller;
}
.collagename{
  top: 3px;
  position: absolute;
  width: 308px;
  left: 93px;
  font-size: 8px;
  margin: 1px;
  padding-left: 15px;
}
.pass-year{
  top: 16px;
  position: absolute;
  width: 308px;
  left: 94px;
  font-size: 8px;
  margin: 1px;
  padding-left: 15px;
}
.group-line-button {
  background: white;
  width: 104px;
  position: absolute;
  top: 81px;
  left: 100px;
  height: 34px;
  /* padding: 3px; */
  margin: 2px;
  border-radius: 4px;
  box-shadow: 0px 24px 32px rgb(12 22 40 / 7%);
}
.img-responsive {
  width: 50px;
  position: relative;
  left: -20px;
  z-index: 1;
  top: 13px;
  /* border-right: 2px solid whitesmoke; */
  padding-right: 10px;
  margin-right: 10px;
}
.name {
 
  text-align: initial;
  /* width: 50px; */
  position: relative;
  left: 32px;
  z-index: 1;
  top: -27px;
  /* border-right: 2px solid whitesmoke; */
  padding-right: 10px;
  margin-right: 10px;
}
.text-xs{
  position: relative;
  left: 214px;
  z-index: 1;
  top: -70px;
  /* border-right: 2px solid whitesmoke; */
  padding-right: 10px;
  margin-right: 10px;
}
.p-t-10 {
  padding-top: 5px !important;
}

.m-l-5 {
  margin-left: 5px;
}
.res-cmnt {
  /* padding: 10px; */
  height: 50px;
}
.sidebar {
  width: 6rem !important;
  background: #ffffff;
}
.logout {
  float: right;
  display: block;
  position: relative;
  top: -20px;
  margin-right: 10px;
  margin-left: 10px;
  color: red;
  background-color: white;
  padding: 6px;
  margin-bottom: -7px;
}
/* .navbar-nav {
  display: list-item;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
} */

}

/*tablet view res*/

@media screen and (device-width: 768px) {
  .logo {
    /* width: 70%; */
    position: absolute;
    width: 81.19px;
    height: 60.68px;
    left: 20px;
    top: 5px;
  }
  .topnav {
    position: absolute;
    width: 519px;
    height: 51px;
    left: 240px;
    top: 26px;
    background: #ffffff;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 4%);
    border-radius: 4px;
}
.textwhite-title {
 
  position: absolute;
  width: 84px;
  height: -14px;
  left: 48px;
  top: 15px;
  line-height: 18px;
  color: #4f4953;

}
.font-face-white-title {
  font-family: "VisbyCF-Heavy";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}
.img-profile {
  position: initial;
  /* width: 352px; */
  /* height: 30px; */
  left: 280px;
  top: 94px;
  /* background: rgba(54, 42, 65, 0.04); */
  border-radius: 4px;
  color: #4f4953;
  height: 53px;
  width: 42px;
  padding-top: 3px;
  padding-left: 2px;
}
.searchbox {
    position: absolute;
    width: 280px;
    height: 36px;
    left: 136px;
    top: 4px;
    background-color: black;
    /* background: rgba(54, 42, 65, 0.04); */
    border-radius: 4px;
}
.user-pic {
      /* position: absolute; */
      width: 0px;
      height: 0px;
      left: 425px;
      font-size: 12px;
      top: 6px;
      /* background: rgba(54, 42, 65, 0.04); */
      border-radius: 4px;
      color: #4f4953;
      
}

/* .sidebar-dark .nav-item .nav-link:active, .sidebar-dark .nav-item .nav-link:focus, .sidebar-dark .nav-item .nav-link:hover {
  color: whitesmoke !important;
  background-color: #4F4953 !important;
  border-radius: 4px;
 /* margin: 10px; */
  /* text-align: center; */
  /* margin-left:5px;
  margin-right: 5px;
  padding: 0px;
  width: 1rem;
} */ 
.sidebar {
  width: 14rem !important;
  background: #ffffff;
}

.sidebar-dark .nav-item .nav-link:active, .sidebar-dark .nav-item .nav-link:focus, .sidebar-dark .nav-item .nav-link:hover {
  color: whitesmoke !important;
  background-color: #4F4953 !important;
  border-radius: 4px;
 /* margin: 10px; */
  /* text-align: center; */
  margin-left:5px;
  margin-right: 5px;
  padding: 1rem;
  width: 11rem;
}
/* .sidebar .nav-item .nav-link {
     /* text-align: center; */
     /* padding: 15px 10px; */ 
     /* width: 6.5rem; */
     /* margin: 5px;
     position: relative;
     top: 17px;
     left: 14px;
} */
 /* .nav-link :hover {
  text-align: center;
  padding: 10px 1rem;
  width: 6.5rem;
  margin: 6px;
  color: whitesmoke !important;
  background-color: #4F4953 !important;
  border-radius: 4px;
} */

.radioButton{
  margin-top: 50px !important;
}


.sidebar .nav-item {
  line-height: 10px;
  margin-left: -12px;
  position: relative;
  left: 0px;
  padding: 0px;
}
.sidebar .nav-item .nav-link {
  display: block;
  width: 100%;
  text-align: left;
  padding: 19px;
  width: 12rem;
  margin: 10px;
  height: 10px;
  position: relative;
  top: -18px;
}
.sidebar .nav-item .nav-link:hover {
  display: block;
  width: 100%;
  text-align: left;
  padding: 19px;
  width: 12rem;
  color: whitesmoke !important;
  background-color: #4F4953 !important;
  position: relative;
    top: -7px;
}

.postcontainer {
  position: absolute;
  width: 492px;
  height: -3px;
  left: 222px;
  top: 56px;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 4%);
  border-radius: 4px;
  margin: 30px;
  padding: 20px;
  height: 110px;
}
.card {
  /* position: relative; */
  margin-bottom: 24px;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 33%);
  left: 242px !important;
  /* width: 488px !important; */
  width: 100% !important;
  top: 210px !important;
}
.btn-post {
  padding: 0 !important;
  top: 50px;
  position: absolute;
  width: 67px;
  height: 26px;
  left: 340px;
  top: -36px;
  background: #4f4953;
  border-radius: 4px;
}
.cmnt-input {
  width: 458px;
  height: 55px;
  border: 1px solid rgba(54, 42, 65, 0.2) !important;
  box-sizing: border-box;
  border-radius: 4px;
  top: 131px;
  position: absolute;
  left: 10px;
  padding-left: -46px;
}
.cmnt-img-responsive {
  width: 50px;
  position: relative;
  left: -5px;
  z-index: 1;
  top: 21px;
  border-right: 2px solid whitesmoke;
  padding-right: 10px;
  margin-right: 10px;
}
.button-cmnt {
  width: 87px;
  position: relative;
  left: 346px;
  z-index: 1;
  top: -16px;
  background: #4f4953 !important;
  color: white !important;
}
.line-hr {
  width: 460px;
  left: 9px;
  position: relative;
}
.time-size {
  /* font-size: 1rem; */
  top: 27px;
  /* position: absolute; */
  left: 364px;
  background: white;
  width: 85px !important;
  font-size: 10px;
  padding: 7px;
}
.p-2 {
  padding: 0px !important;
}
.user-img-responsive {
  width: 38px;
  position: relative;
  left: -12px;
}
.pt-2, .py-2 {
  padding-top: 0px !important;
}
.ml-3, .mx-3 {
  margin-left: 2px !important;
}
.mr-2{
  margin-right: 2px
}
.username{
  top: 8px;
  position: absolute;
  left: 50px;
  margin-right: 2px;
  font-size: smaller;
}
.collagename{
  top: 8px;
  position: absolute;
  width: 308px;
  left: 84px;
  font-size: 10px;
  margin: 1px;
  padding-left: 15px;
}
.pass-year{
  top: 8px;
    position: absolute;
    width: 308px;
    left: 302px;
    font-size: 10px;
    margin: 1px;
    padding-left: 15px;
}
.group-line-button {
  background: white;
  width: 128px;
  position: absolute;
  top: 81px;
  left: 150px;
  height: 34px;
  /* padding: 3px; */
  margin: 2px;
  border-radius: 4px;
  box-shadow: 0px 24px 32px rgb(12 22 40 / 7%);
}
.img-responsive {
  width: 50px;
  position: relative;
  left: -20px;
  z-index: 1;
  top: 13px;
  /* border-right: 2px solid whitesmoke; */
  padding-right: 10px;
  margin-right: 10px;
}
.name {
 
  text-align: initial;
  /* width: 50px; */
  position: relative;
  left: 32px;
  z-index: 1;
  top: 5px;
  /* border-right: 2px solid whitesmoke; */
  padding-right: 10px;
  margin-right: 10px;
}
.text-xs{
  position: relative;
  left: 34px;
  z-index: 1;
  top: 2px;
  /* border-right: 2px solid whitesmoke; */
  padding-right: 10px;
  margin-right: 10px;
}
.p-t-10 {
  padding-top: 5px !important;
}

.m-l-5 {
  margin-left: 5px;
}
.res-cmnt {
  /* padding: 10px; */
  height: 50px;
}
.logout {
  float: right;
  display: block;
  position: relative;
  top: -35px;
  margin-right: 10px;
  margin-left: 10px;
  color: red;
  background-color: white;
  padding: 11px;
  margin-bottom: 35px;
}
.form-control {
  display: block;
  width: 410px;
  height: 46px;
  position: relative;
  left: 50px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc !important;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  outline: 0 !important;
  box-shadow: none !important;
}
}