body {
	 background-color: #1e1e1e;
	 font-family: "VisbyCF-Heavy, sans-serif";
}
 a {
	 text-decoration: none;
	 color: inherit;
}
 ul li {
	 list-style: none;
}
 option{
	background-color: none;
	color:#fff;
}
 .dropdown {
	 padding: 13px 30px;
	 width: 200px;
	 vertical-align: middle;
	 background-color: #362A41;
	 
     text-align: center;
    display: inline-block;
    position: relative;
}
 .dropdown #top {
	 color: #f0f0f0;
	 position: relative;
}
 .dropdown li ul li:hover {
	 color: #f0f0f0;
	 background-color: rgb(54, 42, 65);
	 padding: 13px 30px;
	 width: 200px;
	 vertical-align: middle;
	 margin-left: -30px;
}
 .dropdown li ul li {
	 padding: 13px 30px;
	 width: 200px;
	 vertical-align: middle;
	 margin-left: -30px;
	 color: #1e1e1e;
	 border-bottom: 1px solid #e3e3e3;
	 background-color: rgb(54, 42, 65);
}
 .dropdown li ul li:first-child {
	 margin-top: -13px;
}
 .dropdown li .dropdown-box {
	 height: 0;
	 overflow: hidden;
	 display: none;
	 background-color: #f0f0f0;
	 width: 100%;
	 margin-left: -30px;
	 margin-bottom: -15px;
	 margin-top: 13px;
	 border-top: 20px solid #1e1e1e;
	 padding: 0;
	 padding: 13px 30px;
	 width: 200px;
	 vertical-align: middle;
}
.new-xakal-input{
	height:60px;
	border: 1px solid #B9B7BA;
	border-radius: 4px;
	padding-left:24px;
	font-size:14px;
	
}
 .dropdown li:hover .dropdown-box, .dropdown li:active .dropdown-box {
	 display: block;
	 background-color: #f0f0f0;
	 animation: box 2s ease;
	 animation-iteration-count: 1;
	 animation-fill-mode: forwards;
}
 @keyframes box {
	 from {
		 height: 0;
	}
	 to {
		 height: 203px;
	}
}
 .dropdown li:hover span {
	 width: 0;
	 height: 0;
	 border-left: 5px solid transparent;
	 border-right: 5px solid transparent;
	 border-bottom: 10px solid #f0f0f0;
	 margin-top: 42px;
	 position: absolute;
	 margin-left: -46px;
}


@media (max-width: 1500px){

	.new-xakal-input{
		
		/* width: 181px; */
		height:60px;
		border: 1px solid #B9B7BA;
		border-radius: 4px;
		padding-left:24px;
		font-size:14px;
	}
	/* .row{
		margin-left: 1%;
	} */
	.cls{
		margin-left: 10rem;
	}
}

.dropdown li:hover  {
	cursor: pointer;
}
.card-box-class{
	margin-left: 1rem;
	}
	.class{
		margin-left: 14rem;
	}
	/*get result button */
.getRes-student{
		background-color:  #362A41;
		padding:1rem;
		color: #ffffff;
		box-shadow: 0px 24px 56px rgba(80, 74, 85, 0.27);
		border-radius: 10px;
		margin-top: 7%;	
	}
	@media(max-width:1200px){
		.getRes-student{
			background-color:  #362A41;
			padding:1rem;
			color: #ffffff;
			box-shadow: 0px 24px 56px rgba(80, 74, 85, 0.27);
			border-radius: 10px;
			margin-top: 7%;	
		}
	
	
	/* .labell{
		width: 10rem !important;
    margin-left: 10rem !important;
	} */
	.input-text{
		font-size: 12px;
	}
	
}
	