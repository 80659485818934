.sidebar  {
  /* background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(32,32,147,1) 1%, rgba(5,89,174,1) 13%, rgba(0,212,255,1) 100%); */
 /* position: fixed; */
/* background:  #4F4953; */
  /* border-radius: 4px; */
  /* margin: 10px; */
   /* text-align: center; */
   margin-left:5px;
   margin-right: 5px;
   line-height: 10px;
   position: relative;
   height: 55px;
   top: 5px;
   margin-top: 2px;
}
/* .sidebar .nav-item .nav-link:active {
  display: block !important;
  width: 100% !important;
  text-align: left !important;
  padding: 1rem !important;
  width: 14rem;
} */
.sidebar .nav-item {
  line-height: 10px;
  position: relative;
  height: 50px;
  top: 10px;
  margin-top: 2px;
}
.notes-margin {
  margin-left: 8%;
  border-left: 1px solid #666;
  height: 10%;

}
.assessment-margin {
  margin-left: 10%;
  text-align: center;
}
.am{
  margin-left: 2px;
  font-weight: 600;
}
.width{
  width: 50px;
  font-weight: 600;
  margin-left: -5px;
}

.transport-margin {
  margin-left: 14%;
}
.att-margin{
  margin-left: 9%;
}
.pa-margin{
  margin-left: 10%;
}
.stu-margin{
  margin-left: 4%;
}
.transport-margin{
  margin-left: 16%;
}
.de-margin{
  margin-left: 8%;
}
.pro-margin{
  margin-left: 8%;
}
.logo-color {
  color: #4F4953;
}
.time-margin{
  margin-left: 17%;
}
.col-margin{
  margin-left: 2%;
}
.a-margin{
  margin-left: 12%;

}
.work-margin{
  margin-left: 11%;
}
.co-margin{
  margin-left: 10%;
}
.acc-margin{
  margin-left: 13%;
}
.main-margin{
  margin-left: 5%;
}
.mr{
  margin-left: 2%;
}
.logout {
  /* float: right; */
  display: block;
  margin-right: 10px;
  margin-left: 10px;
  color:red;
  background-color:white;
  /* border: 1px solid red;
  border-radius: 4px; */
  padding:10px;
  margin-bottom: 30px;
 
}
.logoutuser {
  float: right;
  /* display: block; */
  margin-right: 10px;
  margin-left: 10px;
  color:red;
  background-color:white;
  /* border: 1px solid red;
  border-radius: 4px; */
  padding:10px;
  margin-bottom: 10px;
  
}
.notes{
  /* margin-top: -5%; */
  margin-bottom: 5%;
}
.logo {
   width: 70%;
   /* position: absolute; */
   width: 210.19px;
     height: 160.68px;
    left: 1%;
    /* top: 9px; */
    margin-top: 15%;
}

.logo-container {
  width: 40%;
  margin-bottom: 10px;
}

/* .sidebar {
  position: fixed;
  z-index: 999;
}

.wrapper {
  background-color: #eaeef3 !important;
} */

.nav-weight {
  font-weight: 600;
  margin-left: 8px;
}
.nav-weight1 {
  font-weight: 600;
  margin-left: -8px;
}
.ml{
  margin-left: -5px;
}
.mll{
  margin-left: 10px;
}
.ar{
  margin-left: 8px;
}
.co{
  margin-left: 13px;
}
.tm{
  margin-top: -20px;
}
#content {
  background-color: #F6F6F6;
}
/*line */
.vector  {
  position: absolute;
  width: 2px;
  height: 20%;
  left: 30px;
  top: 392px;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  background-color: #f8f9fc;
  
}
/* .vector:active{
  background-color:  #4F4953;

} */
.vector:active {
  background-color:  #4F4953;
}
.vector:focus {
  background-color:  #4F4953;
}
.vector:target {
  background-color:  #4F4953;
}
.sidebar {
  position: fixed;
  height: 100%;
  box-sizing: initial;
  background-color: #FCFBFF;
  /* transition: 0.5s; */
  
  
}
.nav-link{
  background-color:#FFFFFF;
  
}
.sidebar-dark .nav-item .nav-link:active, .sidebar-dark .nav-item .nav-link:focus, .sidebar-dark .nav-item .nav-link:hover {
  /* color: whitesmoke !important; */
  background-color: #362A41!important;
  border-radius: 4px;
 /* margin: 10px; */
  /* text-align: center; */
  margin-left:5px;
  margin-right: 5px;
  padding: 1rem;
  width: 14rem;
  text-align: left !important;
  

}
.pro{height: 15px;
  width: 71px;
  left: 62px;
  top: 525px;
  border-radius: nullpx;
  }
.nav-link{
  height: 40px;
  /* background-color: tomato; */
 

}
/* .typo{
  font-family: Visby CF;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 14px; */
/* letter-spacing: 0.02em; */
/* text-align: left; */


/* .rect {
  position: relative;
width: 1px;
height: 2%;
left: 30px;
top: 300px;
background: #362A41;
border-radius: 1px;
} */
/* .icon-hover:active{
color: #fff;
} */
.icon-hover:hover{
  /* color: #fff; */
  background-color: #f8f9fc;
  
}
.logout {
  float: right;
  display: block;
  position: relative;
  top: -20px;
  margin-right: 10px;
  margin-left: 10px;
  color: red;
  background-color: white;
  padding: 6px;
  margin-bottom: -7px;
}
/* .sidebar .nav-item:hover .nav-link {
   color: #fff; 
}  */
 .nav .nav:active .nav:focus .nav:hover {
  background-color: #4F4953 !important;
  border-radius: 4px;
 /* margin: 10px; */
  /* text-align: center; */
  margin-left:5px;
  margin-right: 5px;
  padding: 1rem;
  width: 14rem;
  text-align: left !important;
 
} 
.html{
  scrollbar-width: normal;
  scrollbar-color:#362A41;
}
.html::-webkit-scrollbar{
  width: 5px;
}

.html::-webkit-scrollbar-thumb{
  background-color: whitesmoke;
  height: 5px;
}
.html::-webkit-scrollbar-track{
  background-color: #362A41;
}
/* .menu{
  width: 150px;
  height: 200px;
 overflow-x: scroll;
  
} */


