.classroom_container {
  padding: 20px;
  margin-left: 20px;
}

.form {
  display: "flex";
}

.flex {
  display: "flex";
  justify-content: center;
  align-items: center;
  margin-left: 5%;
}

.form-btn {
  background-color: #4e73df;
  color: #fff;
  height: 30px;
  padding: 5px;
  box-shadow: 0 10px 30px 0px rgba(00, 100, 70, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(00, 100, 70, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(00, 100, 70, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(00, 100, 70, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(00, 100, 70, 0.5);

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  margin-top: 50px;
  margin-left: 80px;
  border-radius: 5px;
}

.edit-class-btn {
  background-color: #4e73df;
  color: #fff;
  height: 30px;
  padding: 5px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  margin-top: 50px;
  margin-left: 80px;
  border-radius: 5px;
}

.form-btn:hover {
  height: 30px;
  background-color: #4e73df;
  box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
}

.spacing {
  width: 1px;
  height: 1px;
  margin-top: 200px;
}

.btn-close {
  margin-left: 5px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  place-items: center;
  font-size: 12px;
  width: 20px;
  height: 20px;
  background-color: grey;
  border-radius: 2px;
}

.btn-close:hover {
  background-color: #fa2a3b;
}

.btn-add {
  display: flex;
  cursor: pointer;
  justify-content: center;
  place-items: center;
  font-size: 25px;
  width: 20px;
  height: 20px;
  background-color: grey;
  border-radius: 2px,
}

.add-btn-hover-effect:hover {
  background-color: #5cb85c;
}

.dashboard {
  float: 'right'
}

.dashboard:hover {
  color: #74a5f2; 
  cursor: pointer;
}
@media screen and (device-width: 1500px) {
  .media {
    width: 30%;
    position: absolute;
   
  }
  .cls{
     margin-left: 50%;
  }

}
.desi{
 background-color: #362A41;
 border-radius: 8px;
 
  padding: "1rem";
  margin-top: 15%;
   margin-left: '20%' }
  