/**********************************
			1. MENU
**********************************/
.menuBackground {
    background: white;
    height: 148px;
    width: 100%;
 }
 
 .menuBar {
    box-sizing: border-box;
    background-color: #362A41;
    color: white;
    width: 100%;
    height: 64px;
    padding: 16px;
 }
 
 /**********************************
             2. TABLE
 **********************************/
 .ml-table-striped>tbody>tr:nth-of-type(even) {
     background-color: #f9f9f9;
 }
 .mdl-data-table thead {
     background: #FAFAFA;
 }
 .mdl-data-table{
     width: 100% !important;
 }
 .ml-table-bordered>tbody>tr>td, .ml-table-bordered>thead>tr>td {
     border: 1px solid #F0F0F0;
 }
 
 /**********************************
             3. BADGES
 **********************************/
 .badges .ml-card-holder .demo-badge-3[data-badge]:after {
     background-color: #9c27b0;
 }
 
 .mdl-badge .demo-badge-3[data-badge]:after{
      background-color: #9c27b0;
 }
 
 
 
 
 
 .mdl-cell.mdl-cell--3-col.mdl-cell--4-col-tablet {
     padding: 15px 10px 15px 20px;
 }
 .mdl-cell.mdl-cell--3-col.mdl-cell--4-col-tablet:hover {
     background: rgba(0,0,0,.05);
     -webkit-transform: scale(1.05);
     transform: scale(1.05);
 }
 
 .wrapper {
    box-sizing: border-box;
    position: absolute;
    right: 16px;
 }
 @media(min-width:300px){
     .wrapper{
    box-sizing: border-box;
    position: absolute;
    right: 16px;
    margin-left: 4rem
}
 }
 
 .mdl-tabs__tab {
     text-decoration: none !important;
 }
 
 .mdl-gridcell{
     background: #bdbdbd;
   padding: 30px 0;
   text-align: center;
   color: #424242;
   font-weight: bold;
   margin: 8px;
 }
 .mdl-foo{
   background:black;
   padding: 5px;
 }
 .txt-width{
     width: 100% !important;
     max-width: 500px !important;
 }
 .full-width{
     width: 100% !important;
 }
 .select-width{
     width: 100% !important;
     max-width: 300px !important;
 }
 .getmdl-select .mdl-icon-toggle__label {
     float: right;
     margin-top: -30px;
     color: rgba(0,0,0,0.4);
 }
 .mdl-button--fab:focus {
     outline: none !important;
 }
 .mdl-button--icon:focus {
     outline: none !important;
 }
 .mdl-textfield__label {
     color: #bfbebe !important;
     font-size: 13px !important;
 }
 .mdl-textfield__input{
     color: #191313 !important;
     font-size: 13px !important;
 }
 .tab-left-side{
     justify-content: flex-start !important;
     /* margin-left: 1%; */
 }
 .tab-right-side{
     justify-content: flex-end !important;
 }
 .page-header.navbar .page-logo{
     line-height: 1.7 !important;
 }
 .txt-full-width {
     width: 100% !important;
 }
 .menuBar .mdl-button .material-icons {
     color: #ffffff;;
 }
 .mdl-js-button {
     right: 10px;
 }
 .mdl-menu__item{
     font-size: 12px;
 }
 .mdl-menu__item .material-icons{
     padding-right: 10px;
     vertical-align: middle;
 }
 .mdl-cell {
     float: left;
 }
 #headerSettingButton {
     color: #ffffff;
 }
 .mdl-tabs__tab.tabs_three {
     width: calc(100% / 3);
 }
 .mdl-tabs__panel table tr:first-child th {
     border: 0;
 }
 .dtp .dtp-buttons{
     text-align: center !important;
 }
 .dtp-buttons button{
     margin-left: 10px;
 }
 .animation {
    transition-delay: 0.012s;
 }
 .animation1 {
    transition-delay: 0.084;
 }
 .animation2 {
    transition-delay:0.156s;
 }
 .dropdown-list {
    left: 0px; top: 52px; width: 124px; height: 160px;
 }
 .dropdown-div {
    width: 124px; height: 160px;
 }
 .ul-list {
    clip: rect(0px, 124px, 160px, 0px);
 }
 .scrollable-menu {
    height: auto!important;
    max-height: 150px!important;;
    overflow-x: hidden!important;;
}
.display-border {
    border-bottom: 1px solid rgba(0,0,0,.12)!important
}
.is-hidden:after {
    visibility: hidden !important;
}
.is-shown:after {
    visibility: visible!important;
}

.bg-color-white{
    background-color: white;
}

body {
    background-color: white;
}

.student{
    margin-left: 14rem;
}
.card-box-student{
    margin-left: 1rem;
}
.payment{
    margin-left: 14rem;
}
/* .card-box-payment{
    margin-left: 2rem;
} */
 .timetable{
    margin-left: 14rem;
}
.card-box-table{
margin-left: 1rem;
}
.student{
    margin-left: 14rem;
}
.card-box-student{
    margin-left: 1rem;
}
.generate{
    margin-left: 14rem;
}
.view{
    margin-left: 14rem;
} 
.card-box-sem{
    margin-left: 1rem;
}
.add{
    margin-left: 14rem;
}
.card-box-add{
margin-left: 1rem;
}
.time{
    margin-left: 14rem;
}
.card-box-time{
margin-left: 1rem;
}

.trans{
    margin-left: 14rem;
}
.card-box-trans{
margin-left: 1rem;
}
.get-results{
    padding:10px;
    color:#fff;
    background-color:#362A41 ;
    box-shadow: inset 11px -10px 136px #000000;
    border-radius: 8px;
}
.get-result-wrapper{
    margin-top:4.5rem;
}
.del-details{
    padding:10px;
    background-color: #FCFBFF;
     color:#362A41; 
     border :0.4px solid #362A41;
     border-radius:8px ;
     
}
.edit-details{
    background-color:#362A41;
    color:"#fff";
    padding:10px;
    box-shadow: 0px 24px 56px rgba(80, 74, 85, 0.27);
    color:#fff;
    border-radius:8px ;
   
}
.hod-details,.dept-details{
    margin-bottom:1rem;
}
.save{
    margin-right: 2rem;
    background-color:#362A41;
    color:#fff;
    box-shadow: 0px 24px 56px rgba(80, 74, 85, 0.27);
    border-radius:8px;
    
}
.save,.cancel{
    margin-bottom:2rem;
    padding:10px;
    width:80px;
    
}
.cancel {
    background-color: #FCFBFF;
    color:#362A41;
    border:0.6px solid #362A41;
    border-radius:8px;
}

@media only screen and (max-width:1600px){
    .container-fluid{
        margin-left: 16rem !important;
    }
    /* .get-result-wrapper{
        margin-top:0;
    } */
}
@media only screen and (max-width: 992px){
    .new-xakal-input{
        width:90%;
    }
    .get-result-wrapper{
        margin-top:0;
    }
}
@media only screen and (max-width: 770px){
    .container-fluid{
        margin-left: 6rem !important;
    }
    .new-xakal-input{
        width:80%;
    }
    .card-box{
        width:80%;
        margin-left:2rem;
    }
    .get-result-wrapper{
        margin-top:0;
    }
}


@media only screen and (max-width: 576px){
    .container-fluid{
        margin-left: 4rem !important;
        
    }
    .add-r{
        margin-top:2rem !important;
        margin-left:2rem;
        font-size: 24px !important;
        font-weight:500;
       
    }
    .card-box{
       
        margin-left:2rem;
    }
    .course-mgmt{
        margin-left:2rem;
    }
    
    .new-xakal-input{
        width:80%;
        height:50px;
    }
    .edit-details,.del-details{
        
        font-size:16px;
        
    }
    .hod-details,.dept-details{
        margin-left:1rem;
        margin-top:1rem !important;
    }
    .add-dept{
        font-size:22px !important;
        font-weight:600;
    }
    .add-course,.create-table,.add-salary,.non-worker,.teaching-staff
    ,.all-staff
    {
        font-size:20px !important;
        font-weight: 700 !important;
        margin-left:2rem ;
    }
   
    .save{
        margin-right:1rem;
        margin-left: 1rem;
    }
    .save,.cancel{
        width:35%;
    }
    .staff-row{
        margin-left: 2rem;
    }
}