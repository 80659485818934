html {
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  font-family: "VisbyCF-Heavy" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-color: #F6F6F6 !important;
}
.heading-weight {
  font-family: "VisbyCF-Heavy" !important;
  font-weight: 700;
}

.heading-size {
  font-size: 16px !important;
}

.button-size {
  font-size: 14px !important;
}

.grid-cont {
  font-family: "VisbyCF-Heavy" !important;
  font-weight: 700;
  font-size: 12px !important;
}

.label-weight {
  font-family: "VisbyCF-Heavy" !important;
  font-weight: 500;
}

.download {
  color: black;
}

.vertical-border {
  border-right-width: 0 !important;
  border-left-width: 0 !important;
}
.vertical-borders {
  border-right-width: 0 !important;
  border-left-width: 0 !important;
}

.vertical-border-head {
  border-right-width: 0 !important;
  border-left-width: 0 !important;
  border-top-width: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "VisbyCF-Heavy";
  src: local("VisbyCF-Heavy"),
    url("./fonts/VisbyCF/VisbyCF-Regular.otf") format("opentype");
  
}
@font-face {
  font-family: "VisbyCFBold";
  src: local("VisbyCFBold"),
    url("./fonts/VisbyCF/VisbyCF-Bold.otf") format("opentype");
  
}
@font-face {
  font-family: "VisbyCFHeavy";
  src: local("VisbyCFHeavy"),
    url("./fonts/VisbyCF/VisbyCF-Heavy.otf") format("opentype");
  
}
@font-face {
  font-family: "VisbyCFLight";
  src: local("VisbyCFLight"),
    url("./fonts/VisbyCF/VisbyCF-Light.otf") format("opentype");
 
}
@font-face {
  font-family: "VisbyCFMedium";
  src: local("VisbyCFMedium"),
    url("./fonts/VisbyCF/VisbyCF-Heavy.otf") format("opentype");
  
}
@font-face {
  font-family: "VisbyCFThin";
  src: local("VisbyCFThin"),
    url("./fonts/VisbyCF/VisbyCF-Thin.otf") format("opentype");
 
}