.profile-list {
  min-width: 50%;
}
.profile {
  display: flex;
  line-height: 2rem;
  height:auto;
}
.profile-card{
  height:600px;
}
.container-fluid {
  margin-left: 16rem;
  width: 88%;
  height: 100vh;
  background-color: #FCFBFF;
}
.edit-image,.student-follow{
  /* background-color:  #362A41; */
  color:black;
  /* padding:0.4rem; */
}

.student-follow{
  margin-left:17rem;
  margin-right:1rem;
}
.list-unstyled li{
 margin-bottom: 2rem;
}
.list-unstyled .large{
  margin-bottom: 3.2rem;
}

.dashboard-input{
  border:1px solid #B9B7BA;
  border-radius: 4px;
  padding:5px;
  margin-top:0;
}
.card-2{
  width: 37.4vw !important;
  height: 50vh !important;
}
.card-3{
  width: 37.4vw !important;
  margin-bottom: 10px;
}
.card-4{
  box-shadow: 2px 2px 2px 2px #888888;
}
.card-header{
  background: none !important;
}
.card-header h6{
  color:#714F90 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}
img {
  /* border: 1px solid #ddd; */
  border-radius: 4px;
  padding: 5px;
  width: 150px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto ;
  /* background-color: #2196F3; */
  padding: 10px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  padding: 20px;
  font-size: 30px;
  text-align: center;
  /* margin-left:px; */
  width: 17vw !important;
}


/*  */

.card-container {
  height: 300px;
  border-radius: 12px;
  overflow: hidden;
}

.top-card {
  /* background-image: url(http://placekitten.com/900/300); */
  background-color: #ff707e;
  height: 200px;
  display: flex;
  justify-content: left;
  align-items: flex-end;
}

.top-card img {
  width: 200px;
  height: 200px;
  margin-left: 30px !important;
  border-radius: 100%;
  /* border: 4px solid white; */
  transform: translateY(100px);

}

.para-container{
  width: 20vw !important;
  font-weight: bold;
  margin-left: 15rem !important;
}
.para-span{
  margin-left: 5rem;
}
.follower-number{
  margin-left: 21rem !important;
  font-size: 25px !important;
  font-weight: bold;
  color: black;
}
.BContainer{
  padding-top: 4rem;
}
.Card2{
  margin-left: 40px;
}
.follow-span{
  font-weight: 700;
  font-family: "VisbyCF-Heavy, sans-serif";
  font-size: 18px;
  color: #714F90 !important;
}
.vr{
  border-left:1px solid gray;
  height:5vh !important;
  margin-right: 100px;
}
.circle{
        border-radius: 50%;
        width: 34px;
        height: 34px;
        padding: 10px;
        background: #fff;
        border: 3px solid #1fea95;
        color: #000;
        text-align: center;
        font: 32px Arial, sans-serif;
}

.shadow{
  border-radius: 4px !important;
  margin-bottom: 20px;
}
.top-header{
  width: 78vw !important;
  /* box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2), 1px 1px 1px 1px rgba(0, 0, 0, 0.2); */
}

/* search bar */

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  /* padding: 7px 8px; */
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #bbb;
}



button[type="submit"]:focus,
input[type="search"]:focus {
  box-shadow: 0 0 3px 0 gray;
  border-color: gray;
  outline: none;
}


input.nosubmit {
  border: 1px solid #555;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
  margin-left:50px;
}

.dot {
  height: 200px;
  width: 200px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
