
 /*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/
  * {
      margin: 0px; 
      padding: 0px; 
      box-sizing: border-box;
  }
  
  body, html {
      height: 100%;
      font-family: "VisbyCF-Heavy, sans-serif";
  }

  /* width */
::-webkit-scrollbar {
  width: 0.8%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff; 
  box-shadow: inset 0 0 5px #74a5f2; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #4e73df; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #74a5f2; 
}

.hover:hover {
  color: #74a5f2; 
  cursor: pointer;
}

.gray {
  color: 'gray'
}

.active, .blue {
  color: #74a5f2;
}

.add-bus-container {
  border: dashed #74a5f2;
  background-color: gray;
}

.add-bus-container:hover {
  cursor: pointer;
}

.busNumber, .totalStudents {
  display: grid;
  place-items: center;
  background-color: #f5f5ef;
  color: black;
  width: 30;
  height: 30;
}

.busRoute {
  display: grid;
  place-items: center;
  background-color: #f5f5ef;
  color: #74a5f2;
  width: 30;
  height: 30;
}

.bus-section {
  border: solid;
}

.center {
  display: grid;
  place-items: center;
}

.studentsTable, .addBusRoutes {
  padding: 0;
  margin: 0;
  position: absolute;
  display: grid;
  top: 20vh;
}

.btn-outline-danger {
  color: white;
  background: #002933
}

.btn-outline-danger:hover {
  background-color: rgba(255, 0 ,0 0.5);
}

.studentTableElem {
  border:1px solid black;
}

tr ,table{
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  /* text-align: center; */
  margin-left: -30px;
  /* width: 80px;  */
}

th {
  color: 'black';
  font-weight: bold;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
}

td {
  font-weight: normal;
  color: #362A41 !important;
  /* border: 1px solid rgba(0, 0,0, 0.1); */

}
.line2{
  position: absolute;
  width: 100px;
  height: 0px;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
}

/* .tr-hover {
  background-color: #74a5f2 !important;
} */

/* td:hover {
  color: white !important;
} */
  /* ------------------------------------ */
  a {
      margin: 0px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
  }
  
  a:focus {
      outline: none !important;
  }
  
  a:hover {
      text-decoration: none;
  }
  
  /* ------------------------------------ */
  h1,h2,h3,h4,h5,h6 {margin: 0px;}
  
  p {margin: 0px;}
  
  ul, li {
      margin: 0px;
      list-style-type: none;
  }
  
  
  /* ------------------------------------ */
  input {
    display: block;
      outline: none;
      /* border: none !important; */
  }
  
  textarea {
    display: block;
    outline: none;
  }
  
  textarea:focus, input:focus {
    border-color: transparent !important;
  }
  
  /* ------------------------------------ */
  button {
      outline: none !important;
      border: none;
      background: transparent;
  }
  
  button:hover {
      cursor: pointer;
  }
  .tables{
    position:relative;
    right:350px;
  }
  .tables2{
    position:relative;
    left:350px;
  }
  .space{
    padding-left:20px;
  }
  #example4{
    overflow-x:scroll;
  }
  iframe {
      border: none !important;
  }
  /*//////////////////////////////////////////////////////////////////
  [ Table ]*/
  
  .limiter {
    width: 100%;
    margin: 0 auto;
  }
  
  .container-table100 {
    width: 100%;
    min-height: 100%;
    /* background:white; */
  
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 33px 10px;
  }
  
  .wrap-table100 {
    width:100%;
    max-width: 1200px;
  }
  
  /*//////////////////////////////////////////////////////////////////
  [ Table ]*/
  table {
    width: 100%;
  }
  
  th, td {
    font-weight: unset;
    padding-right: 10px;
  }
  th,.vertical-border,.vertical-border-head{
    border:1px solid grey !important;
  }
  .column100 {
    width: 430px;
  }
  
  .column100.column1, .column100.column6 {
    width: 10%;
  }
  .column100.column2{
    width:5%;
  }
  .column100.column3{
    width:8%;
  }
  .column100.column8 {
    width: 20%;
  }
  .row100.head th {
    padding-top: 24px;
    padding-bottom: 20px;
  }
  
  .row100 td {
    padding-top: 18px;
    padding-bottom: 14px;
  }
  
  
  /*==================================================================
  [ Ver5 ]*/
  .table100.ver5 tbody tr:nth-child(even) {
    background-color: #e5e5e59c;
  }
  
  .table100.ver5 td {
    font-family: "VisbyCF-Heavy, sans-serif";
    font-size: 14px;
    color: #808080;
    line-height: 1.4;
    position: relative;
  }
  
  .table100.ver5 th {
    font-family: "VisbyCF-Heavy, sans-serif";
    font-size: 12px;
    color: #fff;
    line-height: 1.4;
    text-transform: uppercase;
  }
  
  .table100.ver5 .row100:hover td {
    color: #4e73df;
  }
  
  .table100.ver5 .hov-column-ver5 {color: #4e73df;}
  .table100.ver5 .hov-column-ver5::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    /* border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2; */
  }
  
  .table100.ver5 .hov-column-head-ver5 {
    background-color: #1a3f48 !important;
    color: #4e73df;
  }
  
  .table100.ver5 .row100 td:hover {
    color: #4e73df;
  }
  
  .table100.ver5 .row100 td:hover:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    border: 1px solid #4e73df;
  }

  /* .-border {
    border:add 0.1px solid #666666 !important;
    padding: 5px;
  } */

  .input-comments {
    border-radius: 2%;
  }

  .right {
    float: right;
  }
  .internal{
    margin-left:14rem;
  }
.card-box-internal{
margin-left: 2rem;
}
.card-box{
  margin-left: 2rem;

}
.semestar{
margin-left:14rem;
}

.flex {
  display: flex;
}