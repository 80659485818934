body {
    background-color: #1e1e1e;
    font-family: "VisbyCF-Heavy, sans-serif";
}
a {
    text-decoration: none;
    color: inherit;
}
ul li {
    list-style: none;
}
.course-dropdown {
    padding: 13px 30px;
    width: 200px;
    vertical-align: middle;
    background-color: rgb(54, 42, 65);
    text-align: center;
    display: inline-block;
    position: absolute;
}
.course-dropdown #top {
    color: #f0f0f0;
    position: relative;
}
.course-dropdown li ul li:hover {
    color: #f0f0f0;
    background-color: rgb(54, 42, 65);
    padding: 13px 30px;
    width: 200px;
    vertical-align: middle;
    margin-left: -30px;
}
.course-dropdown li ul li {
    padding: 13px 30px;
    width: 200px;
    vertical-align: middle;
    margin-left: -30px;
    color: #1e1e1e;
    border-bottom: 1px solid #e3e3e3;
}
.course-dropdown li ul li:first-child {
    margin-top: -13px;
}
.course-dropdown li .course-dropdown-box {
    height: 0;
    overflow: hidden;
    display: none;
    background-color: #f0f0f0;
    width: 100%;
    margin-left: -30px;
    margin-bottom: -15px;
    margin-top: 13px;
    border-top: 20px solid #1e1e1e;
    padding: 0;
    padding: 13px 30px;
    width: 200px;
    vertical-align: middle;
}
.course-dropdown li:hover .course-dropdown-box, .course-dropdown li:active .course-dropdown-box {
    display: block;
    background-color: #f0f0f0;
    animation: box 2s ease;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
@keyframes box {
    from {
        height: 0;
   }
    to {
        height: 203px;
   }
}
.course-dropdown li:hover span {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #f0f0f0;
    margin-top: 42px;
    position: absolute;
    margin-left: -20px;
}

.course-dropdown li:hover {
    cursor: pointer;
}
