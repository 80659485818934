@media (min-width: 992px) and (max-width: 1200px) { 
.container-fluid{
max-width: 100%;
}
.line{
    max-width: 100%;
}
.responsive{
margin-left: -30%;
}
}