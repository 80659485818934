@charset "UTF-8";
/***********************************************************************

						[1. GLOBLE STYLE ]
						
***********************************************************************/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "VisbyCF-Heavy, sans-serif";
  font-weight: 300;
}
h1 {
  font-size: 36px !important;
}
h2 {
  font-size: 30px !important;
}
h3 {
  font-size: 23px !important;
}
h4 {
  font-size: 18px !important;
}
h5 {
  font-size: 14px !important;
}
h6 {
  font-size: 13px !important;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  color: #444;
}
h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
  padding-top: 10px;
  padding-bottom: 10px;
}
.h4,
.h5,
.h6,
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.alert,
.badge,
.btn-group > .btn,
.btn.btn-link:hover,
.btn,
.icon-btn,
.label,
.note,
.panel,
.overview-panel {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18);
}
.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
}
.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.badge,
.input-inline {
  vertical-align: middle;
}
hr {
  margin: 20px 0;
}
a {
  text-shadow: none;
  color: #337ab7;
}
a:hover {
  cursor: pointer;
  color: #23527c;
}
a:active,
a:focus,
a:hover {
  outline: 0;
  text-decoration: none;
}
hr {
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 0;
}
code {
  border: 1px solid #e1e1e1;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.bold {
  font-weight: 700 !important;
}
.sbold {
  font-weight: 600 !important;
}
.thin {
  font-weight: 500 !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.border {
  border: 1px solid red;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.circle {
  border-radius: 25px !important;
}
.circle-right {
  border-radius: 0 25px 25px 0 !important;
}
.circle-left {
  border-radius: 25px 0 0 25px !important;
}
.circle-bottom {
  border-radius: 0 0 25px 25px !important;
}
.circle-top {
  border-radius: 25px 25px 0 0 !important;
}
.rounded {
  border-radius: 50% !important;
}
.display-hide,
.display-none {
  display: none;
}
.hidden {
  display: none !important;
}
.no-margin {
  margin: 0;
}
.mg-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.margin-right-10 {
  margin-right: 10px !important;
}
.visible-ie8 {
  display: none;
}
.ie8 .visible-ie8 {
  display: inherit !important;
}
.visible-ie9 {
  display: none;
}
.ie9 .visible-ie9 {
  display: inherit !important;
}
.hidden-ie8 {
  display: inherit;
}
.ie8 .hidden-ie8 {
  display: none !important;
}
.hidden-ie9 {
  display: inherit;
}
.ie9 .hidden-ie9 {
  display: none !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-r-20 {
  padding-right: 20px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
.p-l-20 {
  padding-left: 20px !important;
}
.p-r-100 {
  padding-right: 100px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-r-100 {
  margin-right: 100px !important;
}
.margin-0 {
  margin: 0 !important;
}
.input-small {
  width: 145px !important;
}
.input-inline {
  width: auto;
}
.font-bold {
  font-weight: bold;
}
.label-rouded {
  border-radius: 50px;
}
.text-center {
  text-align: center;
}
/******* Colors Codes********/

.text-purple {
  color: #9675ce;
}
.text-white {
  color: #ffffff;
}
.text-success {
  color: #36c6d3;
}
.text-info {
  color: #659be0;
}
.text-warning {
  color: #f1c40f;
}
.text-danger {
  color: #ed6b75;
}
.headerBadgeColor1 {
  background-color: #c46210;
}
.headerBadgeColor2 {
  background-color: #673ab7;
}
.orange-bgcolor {
  background-color: #c46210 !important;
}
.purple-bgcolor,
.label.purple-bgcolor {
  background-color: #673ab7;
}
.blue-bgcolor,
.btn.blue-bgcolor {
  background-color: #2196f3;
  color: #fff;
}
.cyan-bgcolor {
  background-color: #36c6d3;
  color: #fff;
}
.deepPink-bgcolor,
.label.deepPink-bgcolor {
  background-color: #e91e63 !important;
  color: #fff !important;
}
.bg-warning {
  color: #fff;
  background-color: #ffd200;
}
.bg-success {
  background: #5fc29d;
  color: #fff;
}

.bg-orange {
  background: #e67d21;
  color: #fff;
}
.bg-blue {
  background: #3598dc;
  color: #fff;
}

.bg-purple {
  background: #8e44ad;
  color: #fff;
}
.bg-danger {
  color: #fff;
  background-color: #e55957;
}
.red {
  color: #fff;
  background-color: #f44336;
}
.pink {
  background-color: #ff1493;
  color: #fff;
}
.yellow {
  background-color: #f1c500;
  color: #fff;
}
.green-color {
  color: green;
}
.state-overview {
  color: #fff;
}
.overview-panel {
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.list-separated {
  margin-top: 10px;
  margin-bottom: 15px;
}
.list-separated > div:last-child {
  border-right: 0;
}
/****** Fontawasome style  *********/

[class*=" fa-"]:not(.fa-stack),
[class*=" glyphicon-"],
[class*=" icon-"],
[class^="fa-"]:not(.fa-stack),
[class^="glyphicon-"],
[class^="icon-"] {
  display: inline-block;
  line-height: 14px;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
}
li [class*=" fa-"],
li [class*=" glyphicon-"],
li [class*=" icon-"],
li [class^="fa-"],
li [class^="glyphicon-"],
li [class^="icon-"] {
  display: inline-block;
  width: 1.25em;
  text-align: center;
}
li [class*=" glyphicon-"],
li [class^="glyphicon-"] {
  top: 2px;
}
li [class*=" icon-"],
li [class^="icon-"] {
  top: 1px;
  position: relative;
}
li [class*=" fa-"].icon-large,
li [class*=" glyphicon-"].icon-large,
li [class*=" icon-"].icon-large,
li [class^="fa-"].icon-large,
li [class^="glyphicon-"].icon-large,
li [class^="icon-"].icon-large {
  width: 1.5625em;
}
.fa-item {
  font-size: 14px;
  padding: 15px 10px 15px 20px;
}
.fa-item i {
  font-size: 16px;
  display: inline-block;
  width: 20px;
  color: #333;
}
.fa-item:hover {
  cursor: pointer;
  background: #eee;
}
/********* Simple line icon  style ***************/

.sl-iconsdemo .item-box {
  display: inline-block;
  font-size: 16px;
  margin: 0 -0.22em 1em 0;
  padding-left: 1em;
  width: 100%;
}
.sl-iconsdemo .item-box .item {
  background-color: #fff;
  color: #33383e;
  border-radius: 8px;
  display: inline-block;
  padding: 10px;
  width: 100%;
}
.sl-iconsdemo .item-box .item span {
  font-size: 22px;
}
.badge-danger,
.badge-default,
.badge-info,
.badge-primary,
.badge-success,
.badge-warning,
.btn.white:not(.btn-outline).active,
.btn.white:not(.btn-outline):active,
.open > .btn.white:not(.btn-outline).dropdown-toggle {
  background-image: none;
}
.alert > p {
  margin: 0;
}
.alert.alert-borderless {
  border: 0;
}
.alert {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  border-width: 0;
}
.margin-top-10 {
  margin-top: 10px;
}
.full-width {
  float: left;
  width: 100%;
}
.hide {
  display: none !important;
}
.img-circle {
  border-radius: 50%;
}
.pull-right {
  float: right !important;
}

/* ***********************************************************************

							    [ 3. PAGE HEADER ]	 
								
**************************************************************************/

.page-container {
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #222c3c;
}
.page-container:after,
.page-container:before {
  content: " ";
  display: table;
}
.page-full-width.page-header-fixed .page-container {
  margin-top: 110px;
}
.page-header .top-menu .dropdown-quick-sidebar-toggler > .dropdown-toggle {
  padding: 19px 10px 10px;
}
.page-header .top-menu .dropdown-quick-sidebar-toggler > .dropdown-toggle i {
  top: 0;
}
.page-header
  .top-menu
  .dropdown-quick-sidebar-toggler
  > .dropdown-toggle
  i:before {
  content: "";
}
.fullscreen-btn {
  display: block;
}
.sidemenu-container {
  margin-top: 50px;
}
.sidemenu-container.fixed-menu {
  position: fixed;
  z-index: 999;
}
.sidemenu-closed .sidemenu-container.fixed-menu {
  position: relative;
}
.sidemenu-container-reversed .sidemenu-container.fixed-menu {
  right: 0;
}

/********************************************************************

						[8. PAGE CONTENT ]
						
*********************************************************************/

.page-title {
  padding: 0;
  font-size: 28px;
  letter-spacing: -1px;
  display: block;
  color: #666;
  margin: 0 0 15px;
  font-weight: 300;
}
.page-title small {
  font-size: 14px;
  letter-spacing: 0;
  font-weight: 300;
  color: #888;
}
.page-container-bg-solid .page-title,
.page-content-white .page-title {
  color: #666;
  margin-bottom: 15px;
  margin-top: 15px;
}
.page-container-bg-solid .page-title small,
.page-content-white .page-title small {
  color: #666;
}
.page-content-white .page-title {
  font-size: 24px;
}
.page-bar {
  padding: 0;
  background-color: #f1f4f7;
  margin-bottom: 25px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
.page-bar:after,
.page-bar:before {
  content: " ";
  display: table;
}
.page-bar .page-breadcrumb {
  display: inline-block;
  float: left;
  padding: 8px;
  margin: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 50px;
  background: rgba(220, 208, 208, 0.3);
  padding-left: 20px !important;
}
.page-bar .page-breadcrumb > li {
  display: inline-block;
}
.ie8 .page-bar .page-breadcrumb > li {
  margin-right: 1px;
}
.page-bar .page-breadcrumb > li > a,
.page-bar .page-breadcrumb > li > span {
  color: #888;
  font-size: 14px;
  text-shadow: none;
}
.page-bar .page-breadcrumb > li > i {
  color: #aaa;
  font-size: 14px;
  text-shadow: none;
}
.page-bar .page-breadcrumb > li > i[class*="icon-"],
.page-bar .page-breadcrumb > li > i[class^="icon-"] {
  color: gray;
}
.page-bar .page-toolbar {
  display: inline-block;
  float: right;
  padding: 0;
}
.page-title-breadcrumb .breadcrumb li + li:before {
  content: "";
  padding: 0;
}
.page-bar .page-toolbar .btn-fit-height {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  padding-top: 8px;
  padding-bottom: 8px;
}
.page-md .page-bar .page-toolbar .btn-fit-height {
  padding-top: 9px;
  padding-bottom: 9px;
  box-shadow: none !important;
}
.page-container-bg-solid .page-bar,
.page-content-white .page-bar {
  background-color: #fff;
  position: relative;
  padding: 0 20px;
  margin: 50px -20px 15px;
}
.page-container-bg-solid .page-bar .page-breadcrumb,
.page-content-white .page-bar .page-breadcrumb {
  padding: 15px 15px;
  margin-top: 10px;
}
.page-container-bg-solid .page-bar .page-breadcrumb > li > a,
.page-container-bg-solid .page-bar .page-breadcrumb > li > span,
.page-content-white .page-bar .page-breadcrumb > li > a,
.page-content-white .page-bar .page-breadcrumb > li > span {
  color: #888;
  text-decoration: none;
}
.parent-item:hover {
  color: #1ebdcc !important;
}
.page-container-bg-solid .page-bar .page-breadcrumb > li > i,
.page-content-white .page-bar .page-breadcrumb > li > i {
  color: #aaa;
}
.page-container-bg-solid .page-bar .page-breadcrumb > li > i.fa-circle,
.page-content-white .page-bar .page-breadcrumb > li > i.fa-circle {
  font-size: 5px;
  margin: 0 5px;
  position: relative;
  top: -3px;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.page-container-bg-solid .page-bar .page-breadcrumb > li > i[class*="icon-"],
.page-container-bg-solid .page-bar .page-breadcrumb > li > i[class^="icon-"],
.page-content-white .page-bar .page-breadcrumb > li > i[class*="icon-"],
.page-content-white .page-bar .page-breadcrumb > li > i[class^="icon-"] {
  color: #8c8c8c;
}
.page-container-bg-solid .page-bar .page-toolbar,
.page-content-white .page-bar .page-toolbar {
  padding: 6px 0;
}
.page-container-bg-solid .page-bar .page-toolbar .btn,
.page-content-white .page-bar .page-toolbar .btn {
  margin-top: -2px;
}
.page-container-bg-solid .page-bar .page-toolbar .btn.btn-sm,
.page-content-white .page-bar .page-toolbar .btn.btn-sm {
  margin-top: 0;
}
.page-content {
  margin-top: 0;
  padding: 0;
  background-color: #fff;
}
.page-full-width .page-content {
  margin-left: 0 !important;
}
/**************************************************************

					[9. FORM ]
					
***************************************************************/

.form {
  padding: 0 !important;
}
.form .form-body,
.form-body {
  padding: 20px;
}
.form .form-body,
.form-body {
  padding-left: 0;
  padding-right: 0;
}
.form .form-actions,
.form-actions {
  padding: 20px;
  margin: 0;
  background-color: #f5f5f5;
}
.form .form-actions,
.form-actions {
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
}
.form .form-actions.nobg,
.form-actions.nobg,
.tile .tile-object {
  background-color: transparent;
}
.form .form-actions.noborder,
.form-actions.noborder {
  border-top: 0;
}
.form .form-actions,
.form-actions {
  -webkit-border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -ms-border-radius: 0 0 2px 2px;
  -o-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
}
.form .form-actions:after,
.form .form-actions:before,
.form-actions:after,
.form-actions:before {
  content: " ";
  display: table;
}
.form .form-actions:after,
.form-actions:after {
  clear: both;
}
.form .form-actions.right,
.form-actions.right {
  padding-left: 0;
  padding-right: 20px;
  text-align: right;
}
.form .form-actions.right,
.form-actions.right {
  padding-right: 0;
}
.form .form-actions.left,
.form-actions.left {
  padding-left: 20px;
  padding-right: 0;
  text-align: left;
}
.control-label {
  margin-top: 1px;
  font-weight: 400;
}
.control-label .required,
.form-group .required {
  color: #e02222;
  font-size: 12px;
  padding-left: 2px;
}
.form-inline input {
  margin-bottom: 0 !important;
}
.checkbox-list > label.checkbox-inline:first-child,
.checkbox > label,
.form-horizontal .checkbox > label,
.form .form-actions.left,
.form-actions.left,
.radio-list > label.radio-inline:first-child {
  padding-left: 0;
}
.form .form-actions.top,
.form-actions.top {
  margin-top: 0;
  margin-bottom: 20px;
  border-top: 0;
}
.form .form-actions.top,
.form-actions.top {
  background: 0 0;
}
.form .form-actions .btn-set,
.form-actions .btn-set {
  display: inline-block;
}
.form .form-section,
.form-section {
  margin: 30px 0;
  padding-bottom: 5px;
}
.form .form-bordered .form-group .help-block,
.form .form-row-seperated .form-group .help-block,
.form-horizontal .radio-list > label {
  margin-bottom: 0;
}
.form-fit .form .form-section,
.form-fit .form-section {
  margin-left: 20px;
  margin-right: 20px;
}
.checkbox,
.form-horizontal .checkbox {
  padding: 0;
}
.radio-list > label {
  display: block;
}
.form-control-static,
.input-inline,
.radio-list > label.radio-inline {
  display: inline-block;
}
.form-horizontal .radio-list .radio {
  padding-top: 1px;
}
.form-horizontal .radio > span {
  margin-top: 2px;
}
.form .form-row-seperated {
  padding: 0;
}
.form .form-row-seperated .form-group {
  margin: 0;
  border-bottom: 1px solid #efefef;
  padding: 15px 0;
}
.form .form-row-seperated .form-group.last {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 13px;
}
.form .form-row-seperated .form-body {
  padding: 0;
}
.form .form-row-seperated .form-actions {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.form .form-bordered .form-group {
  margin: 0;
  border-bottom: 1px solid #efefef;
}
.form .form-bordered .form-group > div {
  padding: 15px;
  border-left: 1px solid #efefef;
}
.form .form-bordered .form-group.last {
  border-bottom: 0;
}
.form .form-bordered .form-group .control-label {
  padding-top: 20px;
}
.form .form-bordered .form-group .form-control {
  margin: 0;
}
.form .form-bordered .form-body {
  margin: 0;
  padding: 0;
}
.form .form-bordered .form-actions {
  margin-top: 0;
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.form
  .form-horizontal.form-bordered.form-row-stripped
  .form-group:nth-child(even) {
  background-color: #fcfcfc;
}
.form .form-horizontal.form-bordered.form-row-stripped .form-control {
  background: #fff !important;
}
.form
  .form-horizontal.form-bordered.form-label-stripped
  .form-group:nth-child(even)
  > div {
  background-color: #fff;
}
.form
  .form-horizontal.form-bordered.form-label-stripped
  .form-group:nth-child(even) {
  background-color: #fcfcfc;
}
.form-horizontal .form-group .input-sm {
  margin-top: 3px;
}
.form-horizontal .form-group .form-control-static {
  margin-top: 1px;
}
.form-control {
  display: block;
  width: 100%;
  height: 39px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  outline: 0 !important;
  box-shadow: none !important;
}
select[multiple],
select[size],
textarea.form-control {
  height: auto;
}
select.input-sm {
  height: 30px !important;
  line-height: 30px;
}
select.form-control.form-control-sm {
  height: 30px !important;
}
.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.input-height {
  height: 45px !important;
}
.form-control.height-auto {
  height: auto;
}
.form-horizontal .control-label.small-label {
  float: left;
  text-align: center;
}
textarea.form-control-textarea {
  float: left;
  width: 100%;
}
.input-group-addon > i {
  color: #c5cdda;
}
.form-control.white {
  border-color: #fff;
}
.form-control.white:focus {
  border-color: #e6e6e6;
}
.form-control.focus-white:focus {
  border-color: #fff;
}
.form-control.default {
  border-color: #e1e5ec;
}
.form-control.default:focus {
  border-color: #c2cad8;
}
.form-control.focus-default:focus {
  border-color: #e1e5ec;
}
.form-control.dark {
  border-color: #2f353b;
}
.form-control.dark:focus {
  border-color: #181c1f;
}
.form-control.focus-dark:focus {
  border-color: #2f353b;
}
.form-control.blue {
  border-color: #3598dc;
}
.form-control.blue:focus {
  border-color: #217ebd;
}
.form-control.focus-blue:focus {
  border-color: #3598dc;
}
.form-control.green {
  border-color: #32c5d2;
}
.form-control.green:focus {
  border-color: #26a1ab;
}
.form-control.focus-green:focus {
  border-color: #32c5d2;
}
.form-control.grey {
  border-color: #e5e5e5;
}
.form-control.grey:focus {
  border-color: #ccc;
}
.form-control.focus-grey:focus {
  border-color: #e5e5e5;
}
.form-control.red {
  border-color: #e7505a;
}
.form-control.red:focus {
  border-color: #e12330;
}
.form-control.focus-red:focus {
  border-color: #e7505a;
}
.form-control.yellow {
  border-color: #c49f47;
}
.form-control.yellow:focus {
  border-color: #a48334;
}
.form-control.focus-yellow:focus {
  border-color: #c49f47;
}
.form-control.purple {
  border-color: #8e44ad;
}
.form-control.purple:focus {
  border-color: #703688;
}
.form-control.focus-purple:focus {
  border-color: #8e44ad;
}
.form-inline .rt-chkbox {
  margin-left: 15px;
  margin-right: 15px;
}
.form-control-static {
  margin: 0;
}
.form-group .input-inline {
  margin-right: 5px;
}
.patient-img img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #fff;
}
.form-material .form-control,
.form-material .form-control.focus,
.form-material .form-control:focus {
  background-image: linear-gradient(#9675ce, #9675ce),
    linear-gradient(rgba(120, 130, 140, 0.13), rgba(120, 130, 140, 0.13));
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  float: none;
}
.form-material .form-control {
  background-color: rgba(0, 0, 0, 0);
  background-position: center bottom, center calc(99%);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  transition: background 0s ease-out 0s;
}
.form-material .form-control.focus,
.form-material .form-control:focus {
  background-size: 100% 2px, 100% 1px;
  outline: 0 none;
  transition-duration: 0.3s;
}

.fileinput .form-control {
  width: 100%;
  position: relative;
}
.fileinput .form-control {
  padding-top: 7px;
  padding-bottom: 5px;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: text;
}
.fileinput-new .input-group-addon {
  position: absolute;
  right: 3px;
  top: 3px;
  z-index: 10 !important;
}

/*****************************************************************

					[10. USER PANEL STYLE ]
						
******************************************************************/

.user-panel {
  float: left;
  width: 100%;
  color: #ccc;
  padding: 10px 10px 20px 10px;
}
.user-online {
  color: #26c281;
}
.user-img-circle {
  background: #fff;
  z-index: 1000;
  position: inherit;
  border: 1px solid rgba(52, 73, 94, 0.44);
  padding: 2px;
}
.txtOnline {
  color: #ccc;
  font-size: 11px;
}
.user-panel .image img {
  max-width: 100%;
}
.user-panel .image {
  width: 35%;
  max-width: 75px;
}
.user-panel .info {
  width: 50%;
  margin-left: 11px;
  margin-top: 6px;
}
.user-panel .info p {
  margin: 5px 0;
}
/*************************************************************

				[11. MODAL WINDOW ]
							
*************************************************************/

.modal {
  z-index: 10050;
  outline: 0;
  overflow-y: auto !important;
}
.modal .modal-header {
  border-bottom: 1px solid #efefef;
}
.modal .modal-header h3 {
  font-weight: 300;
}
.modal .modal-header .close {
  margin-top: 0 !important;
}
.modal .modal-dialog {
  z-index: 10051;
}
.modal > .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
}
.modal.in .page-loading {
  display: none;
}
.modal-open {
  overflow-y: auto !important;
}
.modal-open-noscroll {
  overflow-y: hidden !important;
}
.modal-backdrop {
  border: 0;
  outline: 0;
}
.modal-backdrop,
.modal-backdrop.fade.in {
  background-color: #333 !important;
}
.modal.draggable-modal .modal-header {
  cursor: move;
}
.modal .modal-content {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.18), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.note .highlight,
.note code,
.note.note-default {
  background-color: #fff;
}
body[ng-controller] .modal-backdrop {
  z-index: 10049;
}
body[ng-controller] .modal {
  z-index: 10050;
}
.modal-full.modal-dialog {
  width: 99%;
}
.modal .modal-content {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  border: 0;
}
.modal .modal-content .modal-title {
  font-weight: 400;
}
.modal .modal-content .modal-footer {
  border: 0;
}
.modal-backdrop.in {
  opacity: 0.2;
  filter: alpha(opacity=20);
}
/******************************************************************

					[13. CARD BOX STYLE ]
							
*********************************************************************/

.card {
  position: relative;
  margin-bottom: 24px;
  margin-top: 30px !important;
  margin-bottom: 20px;
  /* background-color: #ffffff; */
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
  width:fit-content;
}
.card-topline-yellow {
  border-top: 3px solid #f39c12;
}
.card-topline-aqua {
  border-top: 3px solid #00c0ef;
}
.card-topline-lightblue {
  border-top: 3px solid #3c8dbc;
}
.card-topline-green {
  border-top: 3px solid #00a65a;
}
.card-topline-red {
  border-top: 3px solid #dd4b39;
}
.card-topline-purple {
  border-top: 3px solid #ad5bff;
}
.card-topline-gray {
  border-top: 3px solid #ddd;
}
.card:before,
.card:after {
  content: " ";
  display: table;
}
.card:after {
  clear: both;
}
.card > .nano:last-child {
  border-radius: 0 0 2px 2px;
}
.card.no-shadow {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.card-head {
  border-radius: 2px 2px 0 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
  padding: 2px;
  /* text-transform: uppercase; */
  color: #3a405b;
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
  min-height: 40px;
}
.card-head:before,
.card-head:after {
  content: " ";
  display: table;
}
.card-head:after {
  clear: both;
}
.card-head header {
  display: inline-block;
  padding: 11px 20px;
  vertical-align: middle;
  line-height: 17px;
  font-size: 20px;
}
.card-head header > h1,
.card-head header > h2,
.card-head header > h3,
.card-head header > h4,
.card-head header > h5,
.card-head header > h6 {
  vertical-align: middle;
  margin: 0;
  line-height: 1;
}
.card-head header small {
  color: inherit;
  opacity: 0.6;
  font-size: 75%;
}
.card-head > div {
  display: inline-block;
}
.card-head .tools {
  padding-right: 16px;
  float: right;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-left: 24px;
  line-height: normal;
  vertical-align: middle;
}
.card-head .tools .btn {
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid transparent;
  padding: 0px 2px;
  font-size: 12px;
  background: transparent;
}
.card-head .tools .btn-color {
  color: #97a0b3;
  margin-right: 3px;
  font-size: 12px;
}
.card-head .tools .btn-color:hover {
  color: black;
}
.card-head.card-head-xs header {
  font-size: 15px;
}
.card-head.card-head-sm header {
  font-size: 15px;
}
.card-head.card-head-lg header {
  font-size: 24px;
}
.card-body {
  padding: 10px 24px 14px 24px;
  position: relative;
}
.card-body:before,
.card-body:after {
  content: " ";
  display: table;
}
.card-body:after {
  clear: both;
}
.card-body:last-child {
  border-radius: 0 0 2px 2px;
}
.card-body.table-responsive {
  margin: 0;
}
.card-actionbar {
  padding-bottom: 8px;
  position: relative;
}
.card-actionbar:before,
.card-actionbar:after {
  content: " ";
  display: table;
}
.card-actionbar:after {
  clear: both;
}
.card-actionbar:last-child {
  border-radius: 0 0 2px 2px;
}
.card-actionbar-row {
  padding: 6px 16px;
  text-align: right;
}
.card-tiles > .row,
.card-type-blog-masonry > .row {
  margin: 0;
}
.card-tiles > .row > [class^="col-"],
.card-type-blog-masonry > .row > [class^="col-"] {
  padding: 0;
}
.card-underline .card-head {
  border-bottom: 1px solid rgba(150, 156, 156, 0.4);
}
.card-underline .card-foot {
  border-top: 1px solid rgba(150, 156, 156, 0.4);
}
.card-bordered,
.card-outlined {
  border: 2px solid #969c9c;
}
.cardbox:hover {
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  transition: all 150ms linear;
}
.cardbox {
  background: #fff;
  min-height: 50px;
  position: relative;
  margin-bottom: 20px;
  transition: 0.5s;
  border: 1px solid #f2f2f2;
  display: inline-block;
  position: relative;
  width: 100%;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.cardbox .header {
  color: #555;
  padding: 0 0 10px 0;
  position: relative;
  border-bottom: 1px solid rgba(204, 204, 204, 0.8);
  box-shadow: none;
  margin-bottom: 0;
  margin: 15px;
  border-radius: 4px;
  padding: 15px 0;
  background-color: #fff;
}
.cardbox .header h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: #3a405b;
  text-transform: uppercase;
}
.cardbox .body {
  font-size: 15px;
  padding: 15px;
  font-weight: 400;
}
.card-box {
  background: #fff;
  min-height: 50px;
  box-shadow: none;
  position: relative;
  margin-bottom: 20px;
  transition: 0.5s;
  border: 1px solid #f2f2f2;
  border-radius: 0;
  width:80%;
}

.course-box {
  padding-left: 20px;
  padding-bottom: 10px;
}
.refresh-block {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.05s ease;
  transition: all 0.05s ease;
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: 1000;
  border-radius: 2px;
}
.refresh-block .refresh-loader {
  display: inline-block;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  margin-left: -16px;
  margin-top: -16px;
}
.refresh-block .refresh-loader i {
  display: inline-block;
  line-height: 32px;
  color: #000;
  font-size: 16px;
}
/************************************************************

					[18. LABEL ]
						
*************************************************************/

label {
  font-weight: 400;
}
.label {
  text-shadow: none !important;
  color: #fff;
  font-family: "VisbyCF-Heavy, sans-serif";
  text-transform: uppercase;
  font-weight: 600;
  padding: 8px 15px;
  background: #1ebdcc;
  line-height: 1;
  text-align: center;
}
.label.label-sm {
  font-size: 13px;
  padding: 2px 5px;
}
h1 .label,
h2 .label,
h3 .label,
h4 .label,
h5 .label,
h6 .label {
  font-size: 75%;
}
.label-event {
  background-color: #dc35a9 !important;
}
.label-event[href]:focus,
.label-event[href]:hover {
  background-color: #9ca8bb !important;
}
.label-primary {
  background: linear-gradient(45deg, #4099ff, #73b4ff) !important;
}
.label-primary[href]:focus,
.label-primary[href]:hover {
  background-color: #286090 !important;
}
.label-success {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5) !important;
}
.label-success[href]:focus,
.label-success[href]:hover {
  background-color: #27a4b0 !important;
}
.label-info {
  background-color: #659be0 !important;
}
.label-info[href]:focus,
.label-info[href]:hover {
  background-color: #3a80d7 !important;
}
.label-warning {
  background: linear-gradient(45deg, #ffb64d, #ffcb80) !important;
}
.label-warning[href]:focus,
.label-warning[href]:hover {
  background-color: #c29d0b !important;
}
.label-danger {
  background: linear-gradient(45deg, #ff5370, #ff869a) !important;
}
.label-danger[href]:focus,
.label-danger[href]:hover {
  background-color: #e73d4a !important;
}
.label.label-icon {
  padding: 4px 0 4px 4px;
  margin-right: 2px;
  text-align: center !important;
}
.label.label-icon > i {
  font-size: 12px;
  text-align: center !important;
}
.ie8 .label.label-icon,
.ie9 .label.label-icon {
  padding: 3px 0 3px 3px;
}
.label-menu {
  margin-right: 30px;
  font-size: 10px;
  border-radius: 60px;
  padding: 6px 8px;
  min-width: 30px;
  height: 20px;
  float: right;
}

.label.label-sm {
  font-size: 10px;
  font-weight: 600;
  padding: 6px 6px;
  margin-right: 5px;
}
.label.label-icon {
  padding: 4px 0 4px 4px !important;
  font-size: 12px !important;
}
.note p,
.page-loading,
.panel .panel-body {
  font-size: 14px;
}
.ie8 .label.label-icon,
.ie9 .label.label-icon {
  padding: 3px 0 3px 3px !important;
}
.sub-title {
  border-bottom: 1px solid rgba(204, 204, 204, 0.35);
  padding-bottom: 10px;
  margin-bottom: 20px;
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #2c3e50;
}
/***************************************************************

					[20. DROP DOWN STYLE ]

****************************************************************/

.dropdown.open > .dropdown-toggle,
.dropup.open > .dropdown-toggle {
  border-color: #ddd;
}
.dropdown-menu {
  left: auto;
  right: 0;
  min-width: 175px;
  position: absolute;
  z-index: 1000;
  display: none;
  float: left;
  list-style: none;
  text-shadow: none;
  padding: 0;
  background-color: #fff;
  font-family: "VisbyCF-Heavy, sans-serif";
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}
.navbar-nav .dropdown-menu {
  position: absolute;
}
.dropdown-menu li.divider {
  background: #f1f3f6;
}
.dropdown-menu li.header {
  padding: 8px 14px 2px;
}
.dropdown-menu > li > a {
  padding: 8px 16px;
  color: #6f6f6f;
  text-decoration: none;
  display: block;
  clear: both;
  font-weight: 300;
  line-height: 18px;
  white-space: nowrap;
}
.dropdown-menu > li > a > [class*=" fa-"],
.dropdown-menu > li > a > [class^="fa-"] {
  color: #888;
}
.dropdown-menu > li > a > [class*=" icon-"],
.dropdown-menu > li > a > [class^="icon-"] {
  color: #666;
}
.dropdown-menu > li > a > [class*=" glyphicon-"],
.dropdown-menu > li > a > [class^="glyphicon-"] {
  color: #888;
}
.dropdown-menu > li.active:hover > a,
.dropdown-menu > li.active > a,
.dropdown-menu > li:hover > a {
  text-decoration: none;
  background-image: none;
  background-color: #f6f6f6;
  color: #555;
  filter: none;
}
.dropdown-menu.bottom-up {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}
.dropdown-menu.bottom-up:after,
.dropdown-menu.bottom-up:before {
  display: none;
}
.dropdown-menu > li:first-child:hover > a {
  border-radius: 2px 2px 0 0;
}
.dropdown-menu > li:last-child:hover > a {
  border-radius: 0 0 2px 2px;
}
.dropdown-hover:hover > .dropdown-menu {
  display: block;
}
.btn-group > .dropdown-menu.pull-left:before,
.dropdown-toggle > .dropdown-menu.pull-left:before,
.dropdown > .dropdown-menu.pull-left:before {
  left: auto;
  right: 9px;
}
.btn-group > .dropdown-menu.pull-left:after,
.dropdown-toggle > .dropdown-menu.pull-left:after,
.dropdown > .dropdown-menu.pull-left:after {
  left: auto;
  right: 10px;
}
.btn-group > .dropdown-menu.pull-right:before,
.dropdown-toggle > .dropdown-menu.pull-right:before,
.dropdown > .dropdown-menu.pull-right:before {
  left: auto;
  right: 9px;
}
.btn-group > .dropdown-menu.pull-right:after,
.dropdown-toggle > .dropdown-menu.pull-right:after,
.dropdown > .dropdown-menu.pull-right:after {
  left: auto;
  right: 10px;
}
.btn-group.dropup > .dropdown-menu,
.dropdown-toggle.dropup > .dropdown-menu,
.dropdown.dropup > .dropdown-menu {
  margin-top: 0;
  margin-bottom: 10px;
}
.btn-group.dropup > .dropdown-menu:after,
.btn-group.dropup > .dropdown-menu:before,
.dropdown-toggle.dropup > .dropdown-menu:after,
.dropdown-toggle.dropup > .dropdown-menu:before,
.dropdown.dropup > .dropdown-menu:after,
.dropdown.dropup > .dropdown-menu:before {
  display: none !important;
}
.dropdown-toggle:after {
  display: none;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 5px;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}
.dropdown-submenu > a:after {
  position: absolute;
  display: inline-block;
  font-size: 14px;
  right: 7px;
  top: 7px;
  font-family: "VisbyCF-Heavy, sans-serif";
  height: auto;
  content: "\f105";
  font-weight: 300;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
}
.nav.pull-right > li > .dropdown-menu,
.nav > li > .dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.nav.pull-right > li > .dropdown-menu:before,
.nav > li > .dropdown-menu.pull-right:before {
  right: 12px;
  left: auto;
}
.nav.pull-right > li > .dropdown-menu:after,
.nav > li > .dropdown-menu.pull-right:after {
  right: 13px;
  left: auto;
}
.nav.pull-right > li > .dropdown-menu .dropdown-menu,
.nav > li > .dropdown-menu.pull-right .dropdown-menu {
  right: 100%;
  left: auto;
  margin-right: -1px;
  margin-left: 0;
}
.dropdown-content {
  padding: 10px;
}
.dropdown-content form {
  margin: 0;
}
.dropdown.inline .dropdown-menu {
  display: inline-block;
  position: relative;
}
.dropdown-menu {
  border: 0 !important;
  border-radius: 2px !important;
  margin: 0;
}
.dropdown-menu:after,
.dropdown-menu:before {
  display: none !important;
}
.dropdown-menu-footer {
  padding: 10px;
  border-top: 1px solid #eff2f6;
  text-align: center;
  float: left;
  width: 100%;
}
.dropdown-menu-footer {
  padding: 10px;
  border-top: 1px solid #eff2f6;
  text-align: center;
  float: left;
  width: 100%;
  background-color: #f3f7f9;
}
.dropdown-menu-footer a {
  color: #a3afb7;
  transition: 0.5s;
}
.dropdown-menu-footer a:hover {
  color: #18b2bf;
  text-decoration: none;
}

.block {
  display: inline-block;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin: 50px;
  width: 500px;
}

.item {
  padding: 20px;
  min-height: 300px;
  background-color: #4e73df;
  border-radius: 10px;
  color: #ffffff;
}
.internal-results,.semester-result,.timetab-results{
  background-color:#362A41 ;
  color:#fff;
  padding:1rem;
  border-radius: 8px;
}
.timetab-results{
  box-shadow: 0px 24px 56px rgba(80, 74, 85, 0.27);
}